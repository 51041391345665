@import "../../shared/styles/styles.scss";

.cash-closing {
    &__breadcrumbs {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: flex-end;
        @include global-typography($color-mine-shaft, bold, 12px, 13px);
    }

    &__title{
        @include global-typography($color-mine-shaft, bold, 16px, 20px);
        padding-left: 10px
    }

    &__label{
        @include global-typography($color-mine-shaft, bold, 14px, 17px);
    }

    &__container{
        margin: 30px 100px 20px;
    }

    &__box{
        background: $color-white;
        @include global-box-shadow(30px);
        margin: 12px auto 20px auto;
        border-radius: 13px;

        &__header{
            padding: 20px;
        }

        &__week{
            @include global-typography($color-mine-shaft, bold, null, 14px);
        }
    }

    &__date {
        width: 85%;
        border: 1px solid $color-nobel;
        height: 35px;
        box-shadow: none;
        cursor: pointer;

        &:hover {
            border: 2px solid $color-pine-glade;
        }

        &.ant-picker-focused {
            border: 2px solid $color-pine-glade;
        }

        &__picker.ant-picker-dropdown{
            &>.ant-picker-panel-container{
                &>.ant-picker-panel-layout>div{
                    &>.ant-picker-panel{
                        &>.ant-picker-date-panel{
                            &>.ant-picker-header{
                                &>.ant-picker-header-view{
                                    &>button{
                                        text-transform: capitalize;
                                        @include global-typography($color-dove-gray,bold,14px);
                                    }
                                }
                            }
                            &>.ant-picker-body{
                                &>.ant-picker-content{
                                    &>thead{
                                        &>tr{
                                            &>th{
                                                text-transform: uppercase;
                                                @include global-typography($color-mine-shaft,bold,14px,15px)
                                            }
                                        }
                                    }
                                    &>tbody{
                                        &>tr{
                                            &>td{
                                                &.ant-picker-cell-today{

                                                    &>div{
                                                        &::before{
                                                            border: 1px solid $color-citron;
                                                        }
                                                    }
                                                }

                                                &.ant-picker-cell-selected{
                                                    &>div{
                                                        background: transparent;
                                                        @include global-typography($color-citron,bold,14px);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &>.ant-picker-month-panel{
                            &>.ant-picker-body{
                                &>.ant-picker-content{
                                    &>tbody{
                                        &>tr{
                                            &>td{
                                                &>div{
                                                    text-transform: capitalize;
                                                }
                                                &.ant-picker-cell-selected{
                                                    &>div{
                                                        background: $color-citron;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &>.ant-picker-year-panel{
                            &>.ant-picker-body{
                                &>.ant-picker-content{
                                    &>tbody{
                                        &>tr{
                                            &>td{
                                                &.ant-picker-cell-selected{
                                                    &>div{
                                                        background: $color-citron;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &>.ant-picker-footer{
                        &>.ant-picker-ranges{
                            margin: 0px;
                            &>.ant-picker-now>a{
                                @include global-button-secondary;
                                padding: 5px;
                            }
                        }
                    }

                }
            }
        }

        &.ant-picker-status-error.ant-picker {
            border: 2px solid $color-lipstick;
        }
    }

    &__select {
        width: 90%;

        &.ant-select {
            &>div.ant-select-selector {
                border: 1px solid $color-nobel;
                border-radius: 5px;
                height: 35px;
                box-shadow: none;
                cursor: pointer;
            }

            &.ant-select-focused {

                &.css-dev-only-do-not-override-ixblex {
                    &.ant-select-single {
                        &.ant-select-show-arrow {

                            &:hover {
                                &>div.ant-select-selector {
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &.ant-select-open {
                                &>.ant-select-selector {
                                    box-shadow: none;
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }
                    }
                }
            }

            &.css-dev-only-do-not-override-ixblex {

                &.ant-select-single {
                    &.ant-select-show-arrow {
                        &:hover {
                            &>div.ant-select-selector {
                                border: 2px solid $color-nobel;
                            }
                        }
                    }
                }
            }
        }

        &.ant-select.ant-select-status-error.ant-select-show-arrow {
            &>.ant-select-selector {
                border: 2px solid $color-lipstick;
            }
        }
    }

    &__button{
        @include global-button-primary(35px)
    }

    &__container-print{

        &__print-header{
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
        }

        &__print-content{
            background-color: $color-silver;
            height: 100%;
            padding: 10px 20px;
        }

        &__print-sheet{
            height: 100%;
            background: $color-white;
            max-height: 520px;
            overflow-y: scroll;

            &::-webkit-scrollbar{
                display: none;
            }
        }

        &__close{
            @include global-typography($color-mine-shaft, bold, 16px, 20px);
        }

        &__title{
            @include global-typography($color-mine-shaft, bold, 16px, 20px);
        }
    }

}