@import "../../../../shared/styles/styles.scss";

.preview-pdf{

    &__content{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: $color-silver;
        padding: 10px;
        overflow-y: scroll;

        &::-webkit-scrollbar{
            overflow: hidden;
            width: 0px;
        }

        &>canvas{
            width: 100% !important;
            height: auto !important;
            margin-top: 20px;
        }
    }
}

//Media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .preview-pdf{
        
        &__content{
            &>canvas{
                margin-top: 70%;
            }
        }
    }
}