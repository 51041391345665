@import "../../../../../../src/shared/styles/styles.scss";

.driver-information {

    &__container {
        width: 100%;
    }

    &__content {
        width: 100%;
        border-radius: 13px;
        background: $color-white;
        margin: 6px auto 20px auto;
        @include global-box-shadow(30px);
    }

    &__photo-profile {
        width: 100px;
        height: 100px;
        margin: 30px 0 10px 0;
    }

    &__box {
        width: 70%;
        height: 80px;
        margin-bottom: 20px;
        align-items: center;
        justify-content: space-evenly;
    }

    &__title-text {
        text-align: center;
        @include global-typography($color-mine-shaft, 700, 16px);
    }

    // TODO: 283580 Implement photo attachment button

    // &__button-change-photo {
    //     width: 65%;
    //     height: 33px;
    //     font-size: 14px;
    //     font-weight: bold;
    //     border-radius: 5px;
    //     color: $color-white;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     background: $color-citron;
        
    //     &:disabled{
    //         cursor: not-allowed;
    //         color: $color-nobel;
    //         background: rgba(0, 0, 0, 0.10);
    //     }
        
    //     &__text {
    //         margin-right: 8%;
    //     }
    // }

    &__edit-profile {
        width: 50%;
        height: 33px;
        font-size: 14px;
        font-weight: bold;
        border-radius: 5px;
        color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-citron;
        
        &__text {
            margin-right: 8%;
        }
    }

    &__icon {
        width: 22px;
        height: 22px;

        &__driver {
            width: 34px;
            height: 34px;
        }
        
        &__permit-application {
            width: 18px;
            height: 18px;
        }

    }

    &__icons-options {
        width: 30px;
        height: 30px;
    }

    &__box-info {
        width: 100%;
        height: 60px;
        border-top: 0.8px solid $color-nobel;

        &--start {
            height: 80px;
            @extend .driver-information__box-info;
        }

        &--finish {
            height: 118px;
            padding-top: 20px;
            display: flex;
            align-items: center;
            flex-direction: column;
            @extend .driver-information__box-info;
        }
    }

    &__text-info {
        @include global-typography($color-mine-shaft, 0, 14px);
    }

    &__select {
        width: 180px;
    }
}
