@import "../../../styles/styles.scss";

.navbar-main{

    &__content{
        position: fixed;
        width: 100%;
        z-index: 2;
        @include global-box-shadow;
    }

    &__text{
        @include global-typography($color-dove-gray,normal,16px);
    }

    &__title{
        @include global-typography($color-mine-shaft,bold,10px);
        margin-left: 5px;
        letter-spacing: 2px;
    }

    &__side-menu{
        margin-left: 5%;

        &__close{

            &.MuiButtonBase-root{
                @include global-box-shadow;
                border-radius: 0 25px 25px 0;
                padding: 10px;
                padding-left: 20px;
                left: -10px;
                padding-right: 20px;
                background: $color-white;
                width: auto;
                
                &:hover{
                    background: $color-white; 
                }

                &>span{
                    display: none;
                }
            }
        }
    }

    &__navbar{
    
        &.MuiToolbar-root{
            background: $color-white;
            min-height: 55px;
            justify-content: space-between;
        }

        &--search{
            margin-right: 5px;
        }

        &--profile{
            margin-right: 10px;
        }

        &--notification{
            margin-right: 20px;
        }

        &__right{
            border-left: 2px solid $color-nobel;
            margin-left: 20px;
            padding-left: 10px;
            margin-right: 30px;
        }
    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .navbar-main{

        &__navbar{
            &__icons{
                &__hidden{
                    display: none;
                }
            }

            &__right{
                border: 0;
                margin: 0;
                padding: 0;
            }

            &--notification{
                margin-right: 10px;
            }
        }
    }
}