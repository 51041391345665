@import "../../../../../../src/shared/styles/styles.scss";

.vehicle-data {

    &__content {
        padding: 0px 2px 11px 2px;

        &>span {
            &.ant-input-affix-wrapper.vehicle-data__input__content.ant-input-affix-wrapper-status-error {
                border: 2px solid $color-lipstick;

                &:hover {
                    border: 2px solid $color-lipstick;
                }
            }
        }

        &__right {
            justify-content: flex-end;
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__label {
        @include global-typography($color-mine-shaft, bold, 13px, 14px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 2px;
        padding-left: 7px;

        &__icon {
            margin: 2px 0 0 3px;
            width: 6px;
            height: 6px;
            border-radius: 6px;
            background-color: $color-lipstick;
        }

        &--mod {
            width: 90%;
        }
    }

    &__select {
        width: 90%;

        &--mod {
            width: 60%;
            margin-left: 5%;
        }

        &.ant-select {
            &>div.ant-select-selector {
                border: 1px solid $color-nobel;
                border-radius: 5px;
                height: 30px;
                box-shadow: none;
                cursor: pointer;
            }

            &.ant-select-focused {

                &.css-dev-only-do-not-override-ixblex {
                    &.ant-select-single {
                        &.ant-select-show-arrow {

                            &:hover {
                                &>div.ant-select-selector {
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &.ant-select-open {
                                &>.ant-select-selector {
                                    box-shadow: none;
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }
                    }
                }
            }

            &.css-dev-only-do-not-override-ixblex {

                &.ant-select-single {
                    &.ant-select-show-arrow {
                        &:hover {
                            &>div.ant-select-selector {
                                border: 2px solid $color-nobel;
                            }
                        }
                    }
                }
            }
        }

        &.ant-select.ant-select-status-error.ant-select-show-arrow {
            &>.ant-select-selector {
                border: 2px solid $color-lipstick;
            }
        }

        &.indicative-number {
            width: 28%;
        }
    }

    &__input {

        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px 22px 3px 3px;
            height: 30px;
            width: 90%;

            &--mod {
                width: 25%;
                @extend .vehicle-data__input__content
            }

            &__numberSeats {
                width: 80%;
                @extend .vehicle-data__input__content
            }

            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }

            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused {
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused {
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus {
                    box-shadow: none;
                }
            }

            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);

            }
        }
    }

    &__content-icon-vehicle {
        width: 10%;
        height: 30px;
        display: flex;
        justify-content: flex-end;

        &__icon {
            height: 100%;
        }
    }

    &__errors {
        height: 10px;
        width: 90%;
        padding-left: 10px;
        @include global-typography($color-lipstick, normal, 10px, 11px)
    }

    &__hidden {
        display: none;
    }

    &__upload-photo {
        width: 90%;
        height: 170px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        border-radius: 13px;
        border: 1px solid $color-nobel;

        &:hover {
            border: 2px solid $color-nobel;
        }

        &__photo-uploaded {
            width: 60px;
            height: 60px;
            border-radius: 8px;
        }

        &__charge-photo-label {
            display: flex;
            width: 100px;
            height: 34px;
            cursor: pointer;
            border-radius: 5px;
            align-items: center;
            flex-direction: row;
            justify-content: space-evenly;
            background: $color-citron;

            &--mod {
                width: 180px;
                @extend .vehicle-data__upload-photo__charge-photo-label;
            }

            &__text {
                font-size: 14px;
                font-weight: bold;
                color: $color-white;
            }
        }

        &__text {
            width: 75%;
            margin-top: 6px;
            text-align: center;
            @include global-typography($color-dove-gray, normal, 8px);
        }

        &__disabled {
            cursor: not-allowed;
            background-color: rgba(0, 0, 0, 0.10);
        }
    }

    &__content-modal {
        width: 100%;
        height: 400px;

        &__content-title {
            height: 28%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-end;
        }

        &__icon {
            height: 40px;
            width: 160px;
        }

        &__title {
            @include global-typography($color-mine-shaft, bold, 14px, 14px);
            margin-top: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__content-structure {
            height: 72%;
            padding: 20px 0;
            display: flex;
            flex-wrap: wrap;
            overflow: scroll;
            align-items: center;
            justify-content: center
        }

        &__container {
            width: 400px;
            margin: 10px 0;
            min-height: 90px;

            &__check-radio-content {
                width: 8%;
            }

            &__check-radio {
                .ant-radio-checked > .ant-radio-inner {
                    border-color: $color-citron;
                    background-color: $color-citron;
                }

                .ant-radio-inner:hover {
                    border-color: $color-citron;
                }

            }

            &__structures {
                margin: 0;
                width: 45%;
                height: 100%;
            }

            &__number-seats {
                margin-bottom: 6px;
                @include global-typography($color-mine-shaft, bold, 14px, 12px);        

                &__content {
                    width: 47%;
                    display: flex;
                    padding-left: 10px;
                    align-items: center;
                }

                &__number {
                    margin-left: 5px;
                    margin-bottom: 6px;
                    @include global-typography($color-mine-shaft, bold, 12px, 12px);
                }
            }
        }
        
    }

    &__date {
        width: 90%;
        height: 30px;
        cursor: pointer;
        box-shadow: none;
        border: 1px solid $color-nobel;

        &:hover {
            border: 2px solid $color-pine-glade;
        }

        &.ant-picker-focused {
            border: 2px solid $color-pine-glade;
        }

        &__picker {
            &>.ant-picker-panel-container {
                &>.ant-picker-panel-layout {
                    &>.ant-picker-panel {
                        &>.ant-picker-date-panel {
                            &>.ant-picker-header {
                                &>.ant-picker-header-view {
                                    &>button {
                                        text-transform: capitalize;
                                        @include global-typography($color-dove-gray, bold, 14px);
                                    }
                                }
                            }

                            &>.ant-picker-body {
                                &>.ant-picker-content {
                                    &>thead {
                                        &>tr {
                                            &>th {
                                                text-transform: uppercase;
                                                @include global-typography($color-mine-shaft, bold, 14px, 15px)
                                            }
                                        }
                                    }

                                    &>tbody {
                                        &>tr {
                                            &>td {
                                                &.ant-picker-cell-today {

                                                    &>div {
                                                        &::before {
                                                            border: 1px solid $color-citron;
                                                        }
                                                    }
                                                }

                                                &.ant-picker-cell-selected {
                                                    &>div {
                                                        background: transparent;
                                                        @include global-typography($color-citron, bold, 14px);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &>.ant-picker-footer {
                            &>a {
                                @include global-button-secondary;
                                padding: 5px;
                            }
                        }

                        &>.ant-picker-month-panel {
                            &>.ant-picker-body {
                                &>.ant-picker-content {
                                    &>tbody {
                                        &>tr {
                                            &>td {
                                                &>div {
                                                    text-transform: capitalize;
                                                }

                                                &.ant-picker-cell-selected {
                                                    &>div {
                                                        background: $color-citron;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }

        &.ant-picker-status-error.ant-picker {
            border: 2px solid $color-lipstick;
        }
    }

    &__content-check {
        width: 90%;
        height: 30px;
        display: flex;
        margin-top: -6px;
        align-items: center;
        justify-content: space-evenly;
        @include global-typography($color-dove-gray,normal,14px,15px);

        &__check{

            &>.ant-checkbox-wrapper,
            &>.ant-checkbox-wrapper:hover{
    
                &>.ant-checkbox{
                    &>.ant-checkbox-inner{
                        border: 1px solid $color-dove-gray;
                    }
    
                    &.ant-checkbox-checked{
    
                        &>span.ant-checkbox-inner{
                            background: $color-wild-willow;
                            border: 1px solid $color-wild-willow;
                        }
    
                        &::after{
                            border-color: transparent;
                        }
                    }
                }

                &>span{
                    margin-left: 8px;
                }
            }
        }
    }
}