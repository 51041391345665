@import "../../../../../shared/styles/styles.scss";

.drivers-table {
    width: 100%;

    &__filters {
        margin: 10px auto;
    }

    &__select {
        width: 80%;

        &__container {
            width: 20%;
        }

        &.ant-select {
            &>div.ant-select-selector {
                border: 1px solid $color-nobel;
                border-radius: 5px;
                height: 34px;
                box-shadow: none;
                cursor: pointer;
            }

            &.ant-select-focused {

                &.css-dev-only-do-not-override-ixblex {
                    &.ant-select-single {
                        &.ant-select-show-arrow {

                            &:hover {
                                &>div.ant-select-selector {
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &.ant-select-open {
                                &>.ant-select-selector {
                                    box-shadow: none;
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }
                    }
                }
            }

            &.css-dev-only-do-not-override-ixblex {

                &.ant-select-single {
                    &.ant-select-show-arrow {
                        &:hover {
                            &>div.ant-select-selector {
                                border: 2px solid $color-nobel;
                            }
                        }
                    }
                }
            }
        }
    }

    &__input {
        width: 100%;

        &__info {
            @include global-typography($color-mine-shaft, 700, 12px);
            width: 10%;
        }
        
        &__content {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 100%;

            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }

            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused {
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused {
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus {
                    box-shadow: none;
                }
            }

            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);

            }
        }

        &__search {
            margin: 0px 5px;
            height: 17px;
            width: 17px
        }
    }

    &__button-search {
        @include global-button-primary(34px, 100px);
    }

    &__download-list {
        @include global-button-secondary(34px, 160px);

        &__icon {
            margin-left: 5%;
        }
    }

    &__box {
        background: $color-white;
        @include global-box-shadow(30px);
        margin: 12px auto 20px auto;
        border-radius: 13px
    }

    &__content-info {
        border-top: 1px solid $color-nobel;
    }

    &__subtitle {
        @include global-typography($color-mine-shaft, bold, 12px, 30px);
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 30px;

        &__content{
            padding: 10px 5px;
            height: 50px;
            justify-content: center;

            &__text{
                padding: 5px;
                justify-content: center;
            }
        }
    }

    &__content-actions-button {
        margin: auto
    }

    &__photo-profile {
        width: 25px;
        height: 25px;
    }

    &__state {
        display: flex;
        width: 50px;
        height: 15px;
        font-size: 8px;
        font-weight: bold;
        border-radius: 8px;
        color: $color-white;
        align-items: center;
        justify-content: center;

        &--active {
            background: $color-citron;
        }

        &--inactive {
            background: $color-lipstick;
        }
    }

    &__button {
        height: 25px;
        font-size: 8px;
        font-weight: bold;
        border-radius: 3px;
        display: flex;
        text-align: left;
        align-items: center;
        justify-content: center;
        
        &__solid {
            width: 100px;
            color: $color-white;
            background: $color-citron;
            @extend .drivers-table__button
        }

        &__border {
            width: 75px;
            color: $color-citron;
            border: 1px solid $color-citron;
            @extend .drivers-table__button
        }

        &__associate {
            width: 100px;
            color: $color-citron;
            border: 1px solid $color-citron;
            justify-content: space-evenly;
            @extend .drivers-table__button
        }

        &__icon {
            width: 16px;
            height: 16px;
        }
    }

    &__info {
        @include global-typography($color-mine-shaft, 700, 12px);
        padding-left: 5px;
    }

    &__icon-associate {
        margin-left: 4px;
        width: 13px;
    }

    &__pagination {

        &.MuiPagination-root {
            border: 1px solid $color-nobel;
            border-radius: 5px;

            &>ul {

                &>li {
                    border-left: 1px solid $color-nobel;

                    &:first-of-type {
                        border-left: 0;
                    }
                }
            }
        }

        &__item {

            &.MuiButtonBase-root {
                margin: 0;
                padding: 0;
                border-radius: 0;
                min-width: 35px;
                height: 35px;
            }
        }

        &__content {
            width: 55%;
            justify-content: flex-end;
        }

    }
}