@import "../../../../shared/styles/styles.scss";

.modal-generate {
    &__title {
        @include global-typography($color-mine-shaft, normal, 14px, 17px);
        margin: 20px auto;
    }
    &__subtitle {
        @include global-typography($color-mine-shaft, bold, 15px, 18px);
        margin-bottom: 15px;
    }

    &__text {
        @include global-typography($color-mine-shaft, normal, 14px, 17px);
        margin-bottom: 15px;
    }

    &__select{
        width: 100%;
        margin-bottom: 20px;
        &.ant-select,
        &.ant-select-show-arrow.ant-select-show-search.ant-select:hover{
            
            
            &>div.ant-select{
                border: 1px solid $color-nobel;
                border-radius: 5px;
                height: 60px;
                box-shadow: none;
                cursor: pointer;
                overflow-x: scroll;
            }
            &>div.ant-select-selector{
                border: 1px solid $color-nobel;
                border-radius: 5px;
                max-height: 60px;
                box-shadow: none;
                cursor: pointer;
                overflow-x: scroll;

                & > .ant-select-selection-overflow {
                    display: flex;
                    flex-wrap: wrap;
                    max-height: 60px;
                  }

                &>.ant-select-selection-overflow>.ant-select-selection-overflow-item>.ant-select-selection-item{
                    background: $color-white;
                    @include global-box-shadow(2px);

                    &>.ant-select-selection-item-content{
                        margin-right: 1px;
                        @include global-typography($color-mine-shaft,bold,12px)
                    }
                }

                &::-webkit-scrollbar{
                    overflow: hidden;
                    width: 0px;
                    height: 0px;
                }

                &>div{
                    flex-wrap: nowrap;
                }
            }

            &.ant-select-multiple{

                &>.ant-select-selector{
                    border: 1px solid $color-nobel;
                }

                &.ant-select-focused {
                    &.css-dev-only-do-not-override-ixblex{

                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-wild-willow;
                            }
                        }
    
                        &.ant-select-open {
                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }
    
                        &>.ant-select-selector {
                            box-shadow: none;
                            border: 2px solid $color-wild-willow;
                        }
                    }
    
                }

                &.css-dev-only-do-not-override-ixblex{
                    &:hover{
                        &>div.ant-select-selector{
                            border: 2px solid $color-nobel;
                        }
                    }
                    &.ant-select-open {
                        &>.ant-select-selector {
                            box-shadow: none;
                            border: 2px solid $color-wild-willow;
                        }
                    }
                }
            }

            &.ant-select-focused {

                &.css-dev-only-do-not-override-ixblex {
                    &.ant-select-single.ant-select-show-arrow {

                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-wild-willow;
                            }
                        }

                        &.ant-select-open {
                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }

                        &>.ant-select-selector {
                            box-shadow: none;
                            border: 2px solid $color-wild-willow;
                        }
                    }
                }
            }

            &.css-dev-only-do-not-override-ixblex{

                &.ant-select-single{
                    &.ant-select-show-arrow{
                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-nobel;
                            }
                        }
                    }
                }
            }
        }

        &.ant-select.ant-select-status-error.ant-select-show-arrow{
            &>.ant-select-selector{
                border: 2px solid $color-lipstick;
            }
        }

        .ant-select-selection-search>.ant-select-selection-search-input{
            box-shadow: none;
        }
    }

    &__input {
        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 30px;
            width: 90%;
            
            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }
            
            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused{
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused{
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus{
                    box-shadow: none;
                }
            }
    
            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);
    
            }
        }
    }

    &__content-check {
        width: 50%;
        height: 30px;
        display: flex;
        margin-top: -6px;
        align-items: center;
        justify-content: space-evenly;
        @include global-typography($color-dove-gray,normal,14px,15px);

        &__check{

            &>.ant-checkbox-wrapper,
            &>.ant-checkbox-wrapper:hover{
    
                &>.ant-checkbox{
                    &>.ant-checkbox-inner{
                        border: 1px solid $color-dove-gray;
                    }
    
                    &.ant-checkbox-checked{
    
                        &>span.ant-checkbox-inner{
                            background: $color-wild-willow;
                            border: 1px solid $color-wild-willow;
                        }
    
                        &::after{
                            border-color: transparent;
                        }
                    }
                }

                &>span{
                    margin-left: 8px;
                }
            }
        }
    }

    &__modal{

        &__button{
            @include global-button-primary(34px, 100%);

            &__mod{
                margin-top: 20px;
            }
        }

        &__buttons{
            @include global-button-secondary(34px, 20%);
        }
    }
}