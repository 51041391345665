@import "../../../../../shared/styles/styles.scss";

.modal-observations{

    &__content{
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__no-comments{
        width: 170px;
        @include global-typography($color-silver,normal,14px,15px)
    }

    &__line{
        width: 111%;
        background: $color-nobel;
        height: 1px;
        margin-top: 20px;
        margin-left: -24px;
    }

    &__subtitle{
        @include global-typography($color-mine-shaft,bold,14px);
        margin-top: 20px;
    }

    &__buttons{
        margin-top: 35px;

        &__add{
            @include global-button-primary(34px,48%)
        }

        &__close{
            @include global-button-secondary(34px,48%)
        }
    }

    &__text-area{

        &.ant-input{
            resize: none;
            @include global-typography($color-dove-gray,normal,12px);
            border-color: $color-nobel;
            box-shadow: none;

            &:focus,
            &:hover:focus{
                border: 2px solid $color-sushi;
            }

            &:hover{
                border: 2px solid $color-nobel;
            }
        }
    }

    &__data{
        width: 100%;
        border-bottom: 1px solid $color-nobel;
        margin-top: 10px;

        &__content{
            height: 130px;
            overflow-y: scroll;

            &::-webkit-scrollbar{
                overflow: hidden;
                width: 0px;
            }
        }

        &__label{
            @include global-typography($color-mine-shaft,bold,12px,13px);
        }

        &__date{
            @include global-typography($color-dove-gray,normal,8px,12px)
        }

        &__info{
            @include global-typography($color-mine-shaft,normal,12px,12px);
        }

        &--mod{
            border-bottom: 0px;
        }
    }
}


//media queries
//Small
@media screen and (max-width: $media-query-small-max) {

    .modal-observations{

        &__buttons{
    
            flex-direction: column;

            &__add{
                width: 100%;
                margin-bottom: 15px;
            }
    
            &__close{
                width: 100%;
            }
        }
    }

}