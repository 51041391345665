@import "../../../../../../src/shared/styles/styles.scss";

.driver-form {

    &__content-close {
        height: 30px;

        &__button {
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        &__close-text {
            font-size: 14px;
            font-weight: bold;
            color: $color-mine-shaft;
        }

        &__icon {
            height: 100%;
        }
    }


    &__container {
        width: 100%;
        border-radius: 13px;
        background: $color-white;
        margin: 12px auto 20px auto;
        padding: 25px 35px 0 35px;
        @include global-box-shadow(30px);
    }

    &__content-button {
        padding: 20px 0;
    }

    &__button {
        width: 110px;
        height: 34px;
        font-size: 14px;
        font-weight: bold;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &__save {
            color: $color-white;
            background: $color-citron;
            @extend .driver-form__button;
        }

        &__disabled {
            cursor: not-allowed;
            background: rgba(0, 0, 0, 0.10);
            @extend .driver-form__button;
        }

        &__next {
            padding-left: 10px;
            @include global-button-secondary(34px, 110px);
            width: auto;

        }

        &__return {
            color: $color-citron;
            background: $color-white;
            border: 2px solid $color-citron;
            @extend .driver-form__button;
        }

        &__icon {
            height: 100%;
        }
    }
}
