@import "../../../../../shared/styles/styles.scss";

.sales-table {

    &__filters {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 5px 2%;
    }

    &__spin {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-top: 170px;

        .ant-spin-dot-item {
            background-color: $color-sushi; 
        }
    
        .ant-spin-text {
            color: black ; 
        }
    }

    &__select {
        width: calc(100% - 45px);

        &__container {
            width: 20%;
        }

        &.ant-select {
            &>div.ant-select-selector {
                border: 1px solid $color-nobel;
                border-radius: 5px;
                height: 34px;
                box-shadow: none;
                cursor: pointer;
            }

            &.ant-select-focused {

                &.css-dev-only-do-not-override-ixblex {
                    &.ant-select-single {
                        &.ant-select-show-arrow {

                            &:hover {
                                &>div.ant-select-selector {
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &.ant-select-open {
                                &>.ant-select-selector {
                                    box-shadow: none;
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }
                    }
                }
            }

            &.css-dev-only-do-not-override-ixblex {

                &.ant-select-single {
                    &.ant-select-show-arrow {
                        &:hover {
                            &>div.ant-select-selector {
                                border: 2px solid $color-nobel;
                            }
                        }
                    }
                }
            }
        }
    }

    &__input {
        &__container {
            width: 33%;
            padding: 0 10px;
        }

        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 36px;

            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }

            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused {
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused {
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus {
                    box-shadow: none;
                }
            }

            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);

            }
        }

        &__reset {
            margin: 0px -2px 0px 0px;
            height: 25px
        }

        &__search {
            margin: 0px 5px;
            height: 17px;
            width: 17px
        }
    }

    &__range-picker {
        border: 1px solid $color-nobel;
        border-radius: 5px;
        height: 34px;
        box-shadow: none;
        width: 100%;

        &__img {
            height: 25px;
        }

        &__modal {
            &>.ant-picker-range-wrapper {
                &>.ant-picker-panel-container {
                    &>.ant-picker-panel-layout {
                        &>div {
                            &>.ant-picker-panels {
                                &>.ant-picker-panel {
                                    &>.ant-picker-date-panel {
                                        &>.ant-picker-header {
                                            &>.ant-picker-header-view {
                                                &>.ant-picker-month-btn {
                                                    text-transform: capitalize;
                                                }
                                            }
                                        }

                                        &>.ant-picker-body {
                                            &>.ant-picker-content {
                                                &>thead {
                                                    &>tr {
                                                        &>th {
                                                            text-transform: uppercase;
                                                            @include global-typography($color-mine-shaft, bold, 14px, 15px);
                                                        }
                                                    }
                                                }

                                                &>tbody {
                                                    &>tr {
                                                        &>.ant-picker-cell-today {
                                                            &>.ant-picker-cell-inner {
                                                                @include global-typography($color-citron, bold, 14px);

                                                                &::before {
                                                                    border: 0px;
                                                                }
                                                            }
                                                        }

                                                        &>.ant-picker-cell-range-start.ant-picker-cell-in-view,
                                                        &>.ant-picker-cell-range-end.ant-picker-cell-in-view {
                                                            background: $color-citron;
                                                            border-radius: 4px;

                                                            &>.ant-picker-cell-inner {
                                                                background: $color-citron;
                                                                @include global-typography($color-white, bold, 14px);
                                                            }

                                                            &::before {
                                                                background: $color-citron;
                                                            }
                                                        }

                                                        &>.ant-picker-cell-in-view.ant-picker-cell-in-range {
                                                            border-top: 1px dashed $color-citron;
                                                            border-bottom: 1px dashed $color-citron;
                                                            background: $color-beryl-green;

                                                            &::before {
                                                                background: $color-beryl-green;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &>.ant-picker-month-panel {
                                        &>.ant-picker-body {
                                            &>.ant-picker-content {
                                                &>tbody {
                                                    &>tr {
                                                        &>td {
                                                            text-transform: capitalize;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.ant-picker {
            &:hover {
                border: 2px solid $color-pine-glade;
            }

            &.ant-picker-focused {
                border: 2px solid $color-citron;

                &>.ant-picker-active-bar {
                    background: transparent;
                }
            }
        }
    }

    &__date {
        width: 49%;
        border: 1px solid $color-nobel;
        height: 34px;
        box-shadow: none;
        cursor: pointer;

        &__container {
            width: 33%;
            padding: 0 10px;

            &--mod{
                width: 33%;padding: 0 10px;
                margin-top: 0;
            }
        }

        &:hover {
            border: 2px solid $color-pine-glade;
        }

        &.ant-picker-focused {
            border: 2px solid $color-pine-glade;
        }

        &__picker {
            &>.ant-picker-panel-container {
                &>.ant-picker-panel-layout {
                    &>.ant-picker-panel {
                        &>.ant-picker-date-panel {
                            &>.ant-picker-header {
                                &>.ant-picker-header-view {
                                    &>button {
                                        text-transform: capitalize;
                                        @include global-typography($color-dove-gray, bold, 14px);
                                    }
                                }
                            }

                            &>.ant-picker-body {
                                &>.ant-picker-content {
                                    &>thead {
                                        &>tr {
                                            &>th {
                                                text-transform: uppercase;
                                                @include global-typography($color-mine-shaft, bold, 14px, 15px)
                                            }
                                        }
                                    }

                                    &>tbody {
                                        &>tr {
                                            &>td {
                                                &.ant-picker-cell-today {

                                                    &>div {
                                                        &::before {
                                                            border: 1px solid $color-citron;
                                                        }
                                                    }
                                                }

                                                &.ant-picker-cell-selected {
                                                    &>div {
                                                        background: transparent;
                                                        @include global-typography($color-citron, bold, 14px);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &>.ant-picker-footer {
                            &>a {
                                @include global-button-secondary;
                                padding: 5px;
                            }
                        }

                        &>.ant-picker-month-panel {
                            &>.ant-picker-body {
                                &>.ant-picker-content {
                                    &>tbody {
                                        &>tr {
                                            &>td {
                                                &>div {
                                                    text-transform: capitalize;
                                                }

                                                &.ant-picker-cell-selected {
                                                    &>div {
                                                        background: $color-citron;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }

    }

    &__button{
        @include global-button-primary(34px,100%);

        &__container{
            width: 14%;
            padding-left: 10px;
        }

        &__secondary{
            @include global-button-secondary(90%,100%);

            &__text{
                font-weight: bold;
                font-size: 8px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

    }

    &__clear {
        border: 2px solid $color-wild-willow;
        border-radius: 5px;
        height: 34px;
        width: 34px;
        margin-left: 10px;
    }

    &__container {
        width: 100%;
    }

    &__content {
        @include global-box-shadow(12px);
        border-radius: 0px 10px 10px 10px;
        background: $color-white;
        margin: auto 2%;
        height: 280px;
        overflow-y: scroll;
    }

    &__content-data {
        border-bottom: 1px solid black;
        justify-content: space-evenly;
        align-items: center;

        &--mod {
            border: 0;
        }
    }

    &__tabs {
        &.MuiButtonBase-root {
            background: $color-white;
            border-radius: 10px 10px 0 0;
            margin-right: 10px;
            @include global-box-shadow(12px);
            height: 30px;
            min-height: 30px;
            text-transform: none;
            min-width: 134px;
            padding: 0px;
            opacity: 0.5;

            &.Mui-selected {
                @include global-typography($color-dove-gray, 700, 14px);
                opacity: 1;
            }
        }

        &__mod {
            @extend .sales-table__tabs;

            &.MuiButtonBase-root {
                margin-right: 0px;
            }
        }

        &__content {

            &.MuiTabs-root {
                min-height: 30px;

                &>div {
                    &>div {
                        height: 30px;
                        margin-top: 12px;
                        margin-left: 2%;
                    }

                    &>span {
                        display: none;
                    }
                }
            }
        }
    }

    &__text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 5px;
        @include global-typography($color-mine-shaft, 400, 10px);
        width: 100%;

        &--mod {
            opacity: 0.5;
        }

        &--cancel{
            color: $color-lipstick;
            font-weight: bold;
        }
    }

    &__title {
        border-bottom: 1px solid black;
        @include global-typography($color-mine-shaft, 700, 12px);

        &--mod {
            height: 29px;
        }
    }

    &__icons {
        display: flex;
        height: 30px;
        align-items: center;
        justify-content: space-evenly;

        &__link{
            line-height: normal;
        }

        &__modal{
            // align-content: center;
            padding-right: 5px;
            padding-bottom: 2px;
            width: 25px;
        }
    }

    &__buttons {
        border: 1px solid $color-nobel;
        height: 32px;
        width: 32px;
        justify-content: center;
        align-items: center;
        display: flex;
        background: $color-white;

        &__text {
            @include global-typography($color-dove-gray, 400, 14px);
        }

        &--left {
            border-radius: 5px 0 0 5px;
        }

        &--right {
            border-radius: 0 5px 5px 0;
        }
    }

    &__info {
        @include global-typography($color-mine-shaft, 700, 12px);
        padding-left: 5px;
    }

    &__pagination {

        &.MuiPagination-root {
            border: 1px solid $color-nobel;
            border-radius: 5px;

            &>ul {

                &>li {
                    border-left: 1px solid $color-nobel;

                    &:first-of-type {
                        border-left: 0;
                    }
                }
            }
        }

        &__item {

            &.MuiButtonBase-root {
                margin: 0;
                padding: 0;
                border-radius: 0;
                min-width: 35px;
                height: 35px;
            }
        }

        &__content {
            width: 55%;
            justify-content: flex-end;
        }
    }

    &__none {
        visibility: hidden;
        height: 0;
        padding: 0 5px;
    }

    &__hidden {
        display: none;
    }

    &__invoice-button{
        background: $color-white;
        border-radius: 5px;
        height: 36px;
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 5px 10px;
        @include global-box-shadow(10px);

        &--mod{
            visibility: hidden;
        }

        &__content{
            display: flex;
            justify-content: space-between;
            width: 400px;
        }

        &__box{
            position: relative;
        }

        &__container{
            position: absolute;
            right: 2%;
            z-index: 1;
        }

        &__text{
            @include global-typography($color-mine-shaft, bold, 12px);
        }
    }

    &__checkbox{

        &>.ant-checkbox-checked{
        
            &>.ant-checkbox-inner {
                background-color: $color-citron;
                border-color: $color-citron;
            }

            &:hover{
                &>.ant-checkbox-inner {
                    background-color: $color-citron;
                    border-color: $color-citron;
                }
            }

        }

        &.ant-checkbox-wrapper{
            &.ant-checkbox-wrapper-checked:hover>.ant-checkbox-checked{
                &>.ant-checkbox-inner {
                    background-color: $color-citron;
                    border-color: $color-citron;
                }
            }
            &:hover>.ant-checkbox{
                &>.ant-checkbox-inner {
                    border-color: $color-citron;
                }
            }
        }

        &>.ant-checkbox-disabled{
            &>.ant-checkbox-inner {
                background-color: $color-alto;
                border-color: $color-nobel;
            }
        }
    }

    &__data-content{
        display: flex;
        padding-right: 10px;
        padding-left: 10px;
        height: 30px;
    }

    &__modal-electronic{

        &__title{
            @include global-typography($color-mine-shaft, bold, 14px);
            margin-top: 15px;
            text-align: center;
        }

        &__info{
            @include global-typography($color-mine-shaft, normal, 12px);
            text-align: center;
            width: 80%;
        }

        &__text{
            @include global-typography($color-dove-gray, bold, 12px);
            text-align: center;
            width: 80%;
        }

        &__button{
            @include global-button-primary(34px,100%);

            &__label{
                @include global-typography($color-white,bold,14px,15px);
            }

            &:disabled {
                background: $color-citron;
                cursor: not-allowed;
            }
        }

        &__close{
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;

            &__label{
                @include global-typography($color-citron,normal,14px,15px);
            }
        }
    }

    &__linking{

        &__label{
            @include global-typography($color-mine-shaft, normal, 14px);
            width: 100%;
            padding: 0 12%;
        }

        &__content{
            margin: 50px auto;
        }
    }

}


//Media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .sales-table {

        &__filters {
            display: flex;
            flex-direction: column;
        }

        &__select {
            width: 85%;
            &__container {
                width: 100%;
                justify-content: space-between;
            }
        }

        &__input {
            &__container {
                width: 100%;
                padding: 0;
                margin-top: 10px;
            }
        }
        
        &__date{
            &__container{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                padding: 0;
                margin-top: 10px;

                &--mod{
                    margin-top: 0;
                }
            }
        }

        &__button{
    
            &__container{
                width: 100%;
                height: 34px;
                padding-left: 0;
                margin-top: 10px;
            }
    
        }

        &__container {
            height: 85%;
            overflow: hidden;
        }
        &__content-pagination {
            height: 75%;
        }

        &__none {
            visibility: visible;
            height: auto;
            padding: 0;
        }

        &__hidden {
            display: flex;
        }

        &__text {
            padding: 0 20px 0 5px;
            max-width: 180px;

            &__content {
                display: flex;
                justify-content: space-between;
                width: 100%;

                &--mod {
                    margin-top: 15px;
                    padding-right: 20px;
                }
            }
        }

        &__hide {
            display: none;
        }

        &__title {
            border: 0;
            padding-left: 20px;
            padding-right: 5px;
        }

        &__content-data {
            padding: 15px 0;
        }

        &__icons {

            width: 25%;
            height: auto;

            &__content {
                height: 20px;
                width: 20px;
            }
        }

        &__view-more {
            margin-right: 5px;
        }

        &__content {
            height: 85%;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            &__navigation {
                padding-top: 3%;
                padding-bottom: 3%;
            }
        }

        &__pagination {

            &.MuiPagination-root {
                width: 95%;

                &>ul {

                    &>li {
                        width: calc(100%/7);
                    }
                }
            }

            &__item {

                &.MuiButtonBase-root {
                    min-width: 100%;
                    height: auto;
                    line-height: 2;
                }

                &.MuiPaginationItem-root {
                    min-width: 100%;
                    height: auto;
                    line-height: 2;
                    padding: 0;
                    margin: 0;
                }
            }

            &__content {
                width: 65%;
            }

        }

        &__data-content{
            height: auto;
        }
    }
}

//Large

@media screen and (min-width: 640px) {
    .sales-table {

        &__text {

            &__content {
                display: flex;
                flex: 1;
                flex-flow: column nowrap;
            }
        }


        &__view-more {
            visibility: hidden;
        }

        &__icons {

            &__content {

                &--mod {
                    display: none;
                }
            }
        }

        &__content {

            &__navigation {
                padding-top: 2%;
                margin: auto 2%;
            }
        }

    }
}