
@import "../../../../shared/styles/styles.scss";

.modal-preview {

    &__input {
        width: 100%;

        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 35px;
            width: 90%;
            
            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }
            
            &.ant-input-affix-wrapper {


                &.ant-input-affix-wrapper-focused{
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused{
                        border: 2px solid $color-sushi;
                    }
                }

                &.modal-preview__input__content.ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {
                    border: 2px solid $color-lipstick;
            
                    &:hover {
                        border: 2px solid $color-lipstick;
                    }
                }

                &:focus{
                    box-shadow: none;
                }
            }
    
            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);
    
            }
        }

        &__search{
            margin: 0px 5px;
            height: 17px;
            width: 17px
        }
    }

    &__select{
        width: 100%;
        margin-bottom: 20px;
        &.ant-select,
        &.ant-select-show-arrow.ant-select-show-search.ant-select:hover{
            
            
            &>div.ant-select{
                border: 1px solid $color-nobel;
                border-radius: 5px;
                height: 60px;
                box-shadow: none;
                cursor: pointer;
                overflow-x: scroll;
            }
            &>div.ant-select-selector{
                border: 1px solid $color-nobel;
                border-radius: 5px;
                max-height: 60px;
                box-shadow: none;
                cursor: pointer;
                overflow-x: scroll;

                & > .ant-select-selection-overflow {
                    display: flex;
                    flex-wrap: wrap;
                    max-height: 60px;
                  }

                &>.ant-select-selection-overflow>.ant-select-selection-overflow-item>.ant-select-selection-item{
                    background: $color-white;
                    @include global-box-shadow(2px);

                    &>.ant-select-selection-item-content{
                        margin-right: 1px;
                        @include global-typography($color-mine-shaft,bold,12px)
                    }
                }

                &::-webkit-scrollbar{
                    overflow: hidden;
                    width: 0px;
                    height: 0px;
                }

                &>div{
                    flex-wrap: nowrap;
                }
            }

            &.ant-select-multiple{

                &>.ant-select-selector{
                    border: 1px solid $color-nobel;
                }

                &.ant-select-focused {
                    &.css-dev-only-do-not-override-ixblex{

                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-wild-willow;
                            }
                        }
    
                        &.ant-select-open {
                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }
    
                        &>.ant-select-selector {
                            box-shadow: none;
                            border: 2px solid $color-wild-willow;
                        }
                    }
    
                }

                &.css-dev-only-do-not-override-ixblex{
                    &:hover{
                        &>div.ant-select-selector{
                            border: 2px solid $color-nobel;
                        }
                    }
                    &.ant-select-open {
                        &>.ant-select-selector {
                            box-shadow: none;
                            border: 2px solid $color-wild-willow;
                        }
                    }
                }
            }

            &.ant-select-focused {

                &.css-dev-only-do-not-override-ixblex {
                    &.ant-select-single.ant-select-show-arrow {

                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-wild-willow;
                            }
                        }

                        &.ant-select-open {
                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }

                        &>.ant-select-selector {
                            box-shadow: none;
                            border: 2px solid $color-wild-willow;
                        }
                    }
                }
            }

            &.css-dev-only-do-not-override-ixblex{

                &.ant-select-single{
                    &.ant-select-show-arrow{
                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-nobel;
                            }
                        }
                    }
                }
            }
        }

        &.ant-select.ant-select-status-error.ant-select-show-arrow{
            &>.ant-select-selector{
                border: 2px solid $color-lipstick;
            }
        }

        .ant-select-selection-search>.ant-select-selection-search-input{
            box-shadow: none;
        }
    }

    &__text{
        @include global-typography($color-mine-shaft,normal,14px);
            
        height: 30px;
    }

    &__subtitle {
        @include global-typography($color-mine-shaft, bold, 14px)

        }

        &__select{
            width: 100%;
            margin-bottom: 20px;
            &.ant-select,
            &.ant-select-show-arrow.ant-select-show-search.ant-select:hover
            {
    
                &>div.ant-select{
                    border: 1px solid $color-nobel;
                    border-radius: 5px;
                    height: 60px;
                    box-shadow: none;
                    cursor: pointer;
                    overflow-x: scroll;
                }
                &>div.ant-select-selector{
                    border: 1px solid $color-nobel;
                    border-radius: 5px;
                    max-height: 60px;
                    box-shadow: none;
                    cursor: pointer;
                    overflow-x: scroll;
                }
            }
        }
}