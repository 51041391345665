@import "../../../shared/styles/styles.scss";

.create-drivers{
    
    &__subtitle{
        @include global-typography($color-mine-shaft,bold,12px,13px);
    }
    
    &__content{
        padding: 20px 10px;
        padding-bottom: 0;
    }
    
    &__container{
        width: 100%;
        padding-top: 10px;
        justify-content: space-between;
        transition:  width .2s;

        &__drivers-data{
            width: 30%;
            height: 100%;
            padding-left: 130px;
            transition: padding .2s;
        }

        &__drivers-form {
            width: 68%;
            height: 100%;
            padding-right: 130px;
            transition: padding .2s
        }
    }
}
