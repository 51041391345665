@import "../../../../../shared/styles/styles.scss";

.trip-data{

    &__title{
        @include global-typography($color-mine-shaft,bold,16px,17px);
        margin-bottom: 10px;
    }

    &__input-number{
        height: 34px;
        box-shadow: none;
        width: 84%;

        &__content{
            border: 1px solid $color-nobel;
            border-radius: 5px;

            transition: border;

            &--error{
                @extend .trip-data__input-number__content;
                border: 2px solid $color-lipstick;
            }
            
            &:hover{
                border: 2px solid $color-nobel;
            }

        }

        &>.ant-input-number-handler-wrap{
            opacity: 1;
        }


        &__buttons{
            border-inline-start: 1px solid $color-nobel;
            width: 16%;
            height: 34px;
            justify-content: center;
            align-items: center;

            &__icon{
                margin-top: -3px;
            }

            &__disabled{
                background-color: $color-gallery;
                opacity: 0.6;
            }

            &__state:disabled{
                background-color: $color-gallery;
                opacity: 0.6;
                width: 100%;
            }
        }
    }

    &__label{
        @include global-typography($color-mine-shaft,bold,14px,15px);
        margin-bottom: 5px;
        margin-top: 10px;
    }

    &__assigned-seats{
        width: 100%;
        min-height: 50px;

        &__seat{
            width: 86px;
            height: 24px;
            border-radius: 4px;
            margin: 0 5px;
            align-items: center;
            justify-content: space-evenly;
            @include global-box-shadow(10px);
            @include global-typography($color-mine-shaft, bold, 12px, 13px)
        }
    }

    &__content{
        justify-content: space-between;
    }

    &__date{
        width: 100%;
        border: 1px solid $color-nobel;
        height: 34px;
        box-shadow: none;
        cursor: pointer;

        &:hover{
            border: 2px solid $color-pine-glade;
        }

        &.ant-picker-focused{
            border: 2px solid $color-pine-glade;
        }

        &__picker.ant-picker-dropdown{
            &>.ant-picker-panel-container{
                &>.ant-picker-panel-layout>div{
                    &>.ant-picker-panel{
                        &>.ant-picker-date-panel{
                            &>.ant-picker-header{
                                &>.ant-picker-header-view{
                                    &>button{
                                        text-transform: capitalize;
                                        @include global-typography($color-dove-gray,bold,14px);
                                    }
                                }
                            }
                            &>.ant-picker-body{
                                &>.ant-picker-content{
                                    &>thead{
                                        &>tr{
                                            &>th{
                                                text-transform: uppercase;
                                                @include global-typography($color-mine-shaft,bold,14px,15px)
                                            }
                                        }
                                    }
                                    &>tbody{
                                        &>tr{
                                            &>td{
                                                &.ant-picker-cell-today{

                                                    &>div{
                                                        &::before{
                                                            border: 1px solid $color-citron;
                                                        }
                                                    }
                                                }

                                                &.ant-picker-cell-selected{
                                                    &>div{
                                                        background: transparent;
                                                        @include global-typography($color-citron,bold,14px);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &>.ant-picker-month-panel{
                            &>.ant-picker-body{
                                &>.ant-picker-content{
                                    &>tbody{
                                        &>tr{
                                            &>td{
                                                &>div{
                                                    text-transform: capitalize;
                                                }
                                                &.ant-picker-cell-selected{
                                                    &>div{
                                                        background: $color-citron;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &>.ant-picker-year-panel{
                            &>.ant-picker-body{
                                &>.ant-picker-content{
                                    &>tbody{
                                        &>tr{
                                            &>td{
                                                &.ant-picker-cell-selected{
                                                    &>div{
                                                        background: $color-citron;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &>.ant-picker-footer{
                        &>.ant-picker-ranges{
                            margin: 0px;
                            &>.ant-picker-now>a{
                                @include global-button-secondary;
                                padding: 5px;
                            }
                        }
                    }

                }
            }
        }
        
        &.ant-picker-status-error.ant-picker{
            border: 2px solid $color-lipstick;
        }
    }

    &__hour{
        width: 100%;
        border: 1px solid $color-nobel;
        border-radius: 5px;
        height: 34px;
        box-shadow: none;
        cursor: pointer;

        &:hover{
            border: 2px solid $color-wild-willow;
        }

        &.ant-picker-focused{
            border: 2px solid $color-wild-willow;
        }

        &__icon{
            width: 28px;
        }

        &__select{
            &.ant-picker-dropdown{
                &>.ant-picker-panel-container.ant-picker-time-panel-container{
                    &>.ant-picker-panel-layout>div{

                        &>.ant-picker-panel{

                            &>.ant-picker-time-panel{
                                &>.ant-picker-content{
                                    &>.ant-picker-time-panel-column{
                                        &>.ant-picker-time-panel-cell-selected{
                                            &>.ant-picker-time-panel-cell-inner{
                                                background: $color-citron;
                                                @include global-typography($color-white,bold,14px);
                                            }
                                        }
    
                                        &>.ant-picker-time-panel-cell{
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                        }
                                    }
                                }
                            }

                        }

                        &>.ant-picker-footer{
                            border-top: 1px solid $color-silver;
                            &>.ant-picker-ranges{
                                margin-left: 0;
                                &>.ant-picker-ok{
                                    &>button{
                                        @include global-button-primary(34px,100%);
                                    }
                                }
    
                                &>.ant-picker-now{
                                    @include global-button-secondary(34px);
                                    display: flex;
                                    align-items: center;
                                    margin-right: 10px;
    
                                    &>a{
                                        padding: 10px;
                                        @include global-typography($color-citron,bold,14px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.ant-picker-status-error.ant-picker{
            border: 2px solid $color-lipstick;
        }
    }

    &__select{
        width: 100%;
        &.ant-select{
            &>div.ant-select-selector{
                border: 1px solid $color-nobel;
                border-radius: 5px;
                height: 34px;
                box-shadow: none;
                cursor: pointer;
                overflow-x: scroll;

                &>.ant-select-selection-overflow>.ant-select-selection-overflow-item>.ant-select-selection-item{
                    background: $color-white;
                    @include global-box-shadow(2px);

                    &>.ant-select-selection-item-content{
                        @include global-typography($color-mine-shaft,bold,12px)
                    }
                }

                &::-webkit-scrollbar{
                    overflow: hidden;
                    width: 0px;
                    height: 0px;
                }

                &>div{
                    flex-wrap: nowrap;
                }

                &>.ant-select-selection-search{
                    &>input{
                        box-shadow: none;
                        &:focus{
                            box-shadow: none;
                        }
                    }
                }
            }

            &.ant-select-multiple{

                &>.ant-select-selector{
                    border: 1px solid $color-nobel;
                }

                &.ant-select-focused {
                    &.css-dev-only-do-not-override-ixblex{

                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-wild-willow;
                            }
                        }
    
                        &.ant-select-open {
                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }
    
                        &>.ant-select-selector {
                            box-shadow: none;
                            border: 2px solid $color-wild-willow;
                        }
                    }
    
                }

                &.css-dev-only-do-not-override-ixblex{
                    &:hover{
                        &>div.ant-select-selector{
                            border: 2px solid $color-nobel;
                        }
                    }
                    &.ant-select-open {
                        &>.ant-select-selector {
                            box-shadow: none;
                            border: 2px solid $color-wild-willow;
                        }
                    }
                }
            }

            &.ant-select-focused {

                &.css-dev-only-do-not-override-ixblex {
                    &.ant-select-single.ant-select-show-arrow {

                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-wild-willow;
                            }
                        }

                        &.ant-select-open {
                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }

                        &>.ant-select-selector {
                            box-shadow: none;
                            border: 2px solid $color-wild-willow;
                        }
                    }
                }
            }

            &.css-dev-only-do-not-override-ixblex{

                &.ant-select-single{
                    &.ant-select-show-arrow{
                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-nobel;
                            }
                        }
                    }
                }
            }
        }
        
        &.ant-select-show-arrow.ant-select-show-search.ant-select:hover{
            &>div.ant-select-selector{
                border: 1px solid $color-nobel;
                border-radius: 5px;
                height: 34px;
                box-shadow: none;
                cursor: pointer;
                overflow-x: scroll;

                &>.ant-select-selection-overflow>.ant-select-selection-overflow-item>.ant-select-selection-item{
                    background: $color-white;
                    @include global-box-shadow(2px);

                    &>.ant-select-selection-item-content{
                        @include global-typography($color-mine-shaft,bold,12px)
                    }
                }

                &::-webkit-scrollbar{
                    overflow: hidden;
                    width: 0px;
                    height: 0px;
                }

                &>div{
                    flex-wrap: nowrap;
                }
            }

            &.ant-select-multiple{

                &>.ant-select-selector{
                    border: 1px solid $color-nobel;
                }

                &.ant-select-focused {
                    &.css-dev-only-do-not-override-ixblex{

                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-wild-willow;
                            }
                        }
    
                        &.ant-select-open {
                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }
    
                        &>.ant-select-selector {
                            box-shadow: none;
                            border: 2px solid $color-wild-willow;
                        }
                    }
    
                }

                &.css-dev-only-do-not-override-ixblex{
                    &:hover{
                        &>div.ant-select-selector{
                            border: 2px solid $color-nobel;
                        }
                    }
                    &.ant-select-open {
                        &>.ant-select-selector {
                            box-shadow: none;
                            border: 2px solid $color-wild-willow;
                        }
                    }
                }
            }

            &.ant-select-focused {

                &.css-dev-only-do-not-override-ixblex {
                    &.ant-select-single.ant-select-show-arrow {

                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-wild-willow;
                            }
                        }

                        &.ant-select-open {
                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }

                        &>.ant-select-selector {
                            box-shadow: none;
                            border: 2px solid $color-wild-willow;
                        }
                    }
                }
            }

            &.css-dev-only-do-not-override-ixblex{

                &.ant-select-single{
                    &.ant-select-show-arrow{
                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-nobel;
                            }
                        }
                    }
                }
            }
        }

        .ant-select-selection-search>.ant-select-selection-search-input{
            box-shadow: none;
        }

        &.ant-select.ant-select-status-error.ant-select-show-arrow{
            &>.ant-select-selector{
                border: 2px solid $color-lipstick;
            }
        }
    }

    &__errors{
        height: 10px;
        width: 90%;
        padding-left: 10px;
        @include global-typography($color-lipstick, normal, 10px, 11px)
    }

    &__vehicle{
        padding: 10px 0;

        &__description{
            @include global-typography($color-mine-shaft, bold, 10px, 10px)
        }
    }

    &__check{
        margin-right: 5px;

        &.css-dev-only-do-not-override-ixblex,
        &.css-dev-only-do-not-override-ixblex:hover{

            &>.ant-checkbox{
                &.ant-checkbox-checked{
                
                    &>.ant-checkbox-inner{
                        background-color: $color-citron;
                        border-color: $color-citron;
                    }

                    &::after{
                        border-color: $color-citron;
                    }
                }
                &:hover>.ant-checkbox-inner{
                    border-color: $color-citron;
                }
            }
        }

        &.ant-checkbox-wrapper-disabled{

            &>.ant-checkbox.ant-checkbox-disabled{

                &>.ant-checkbox-inner{
                    background-color: $color-nobel;
                    border-color: $color-nobel;
                }
            }
        }
    }

    &__modal{

        &__button{
            @include global-button-primary(34px, 100%);
        }

        &__title {
            @include global-typography($color-mine-shaft, normal, 14px, 17px);
            margin: 20px 35px;
        }
    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .trip-data{

        &__title{
            margin-bottom: 0px;
        }
        
        &__label{
            margin-top: 20px;
            margin-left: 7px;
        }

    }
}