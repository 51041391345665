@import "../../../../../shared/styles/styles.scss";

.vehicles-table {
    width: 100%;

    &__filters {
        margin: 10px auto;
    }

    &__select {
        width: 80%;

        &__container {
            width: 20%;
        }

        &.ant-select {
            &>div.ant-select-selector {
                border: 1px solid $color-nobel;
                border-radius: 5px;
                height: 34px;
                box-shadow: none;
                cursor: pointer;
            }

            &.ant-select-focused {

                &.css-dev-only-do-not-override-ixblex {
                    &.ant-select-single {
                        &.ant-select-show-arrow {

                            &:hover {
                                &>div.ant-select-selector {
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &.ant-select-open {
                                &>.ant-select-selector {
                                    box-shadow: none;
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }
                    }
                }
            }

            &.css-dev-only-do-not-override-ixblex {

                &.ant-select-single {
                    &.ant-select-show-arrow {
                        &:hover {
                            &>div.ant-select-selector {
                                border: 2px solid $color-nobel;
                            }
                        }
                    }
                }
            }
        }
    }

    &__input {
        width: 100%;

        &__info {
            @include global-typography($color-mine-shaft, 700, 12px);
            width: 10%;
        }
        
        &__content {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 100%;

            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }

            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused {
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused {
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus {
                    box-shadow: none;
                }
            }

            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);

            }
        }

        &__search {
            margin: 0px 5px;
            height: 17px;
            width: 17px
        }
    }

    &__button-search {
        @include global-button-primary(34px, 100px);
    }

    &__download-list {
        @include global-button-secondary(34px, 160px);

        &__icon {
            margin-left: 5%;
        }
    }

    &__box {
        background: $color-white;
        @include global-box-shadow(30px);
        margin: 12px auto 20px auto;
        border-radius: 13px
    }

    &__content-info {
        border-top: 1px solid $color-nobel;
    }

    &__subtitle {
        @include global-typography($color-mine-shaft, bold, 12px, 30px);
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 30px;

        &__content{
            display: flex;
            padding: 10px 5px;
            height: 50px;
            justify-content: center;

            &__text{
                padding: 5px;
                justify-content: center;
            }
        }

        &__box{
            width: 13%;

            &--mod{
                width: 35%;
            }
        }

        &__container{
            justify-content: center;
            align-items: center;
            align-content: center;
        }

    }

    &__photo-profile {
        width: 25px;
        height: 25px;
    }

    &__state {
        display: flex;
        padding: 2px 9px;
        height: 16px;
        font-size: 9px;
        font-weight: bold;
        border-radius: 8px;
        color: $color-white;
        align-items: center;
        justify-content: center;
        width: 100px;

        &--available {
            background: $color-citron;
        }

        &--maintenance {
            background: $color-nobel;
        }

        &--inactive {
            background: $color-lipstick;
        }

        &--on-route {
            background: $color-citron;
            opacity: 0.5;
        }
    }

    &__button {
        height: 25px;
        font-size: 8px;
        font-weight: bold;
        border-radius: 3px;
        display: flex;
        text-align: left;
        align-items: center;
        justify-content: space-around;
        min-width: 100px;
        
        &--solid {
            width: 80px;
            color: $color-white;
            background: $color-citron;
            @extend .vehicles-table__button
        }

        &--border {
            width: 80px;
            color: $color-citron;
            border: 1px solid $color-citron;
            @extend .vehicles-table__button
        }
    }

    &__info {
        @include global-typography($color-mine-shaft, 700, 12px);
        padding-left: 5px;
    }

    &__icon-associate {
        margin-left: 4px;
    }

    &__pagination {

        &.MuiPagination-root {
            border: 1px solid $color-nobel;
            border-radius: 5px;

            &>ul {

                &>li {
                    border-left: 1px solid $color-nobel;

                    &:first-of-type {
                        border-left: 0;
                    }
                }
            }
        }

        &__item {

            &.MuiButtonBase-root {
                margin: 0;
                padding: 0;
                border-radius: 0;
                min-width: 35px;
                height: 35px;
            }
        }

        &__content {
            width: 55%;
            justify-content: flex-end;
        }

    }

    &__hidden{
        display: none;
    }

    &__actions{
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        &__button{
            width: 70%;

            &__edit{
                width: 75%;
                flex-flow: row wrap;
            }
        }
    }

    &__modal{

        &__cancel{
            margin-top: 10px;
            @include global-button-secondary(34px, 100%);
        }

        &__text{
            @include global-typography($color-mine-shaft, 600, 16px)
        }
    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .vehicles-table {

        &__box{
            :nth-child(n + 7):not(:last-child) {
                border-bottom: 1px solid $color-nobel;
            }

            overflow-y: scroll;
            @include global-box-shadow(12px);
            width: 98%;

            ::-webkit-scrollbar{
                width: 0px;
            }
        }

        &__hidden{
            display: flex;
        }

        &__clear{
            width: 20%;
            justify-content: end;
            align-items: center;

            &__button{
                border: 2px solid $color-wild-willow;
                border-radius: 5px;
                height: 34px;
                width: 34px;
            }
        }

        &__input {

            &__info {
                display: none;
            }

            &__content{
                margin: 10px 0px;
                width: 100%;
                height: 34px;
            }
        }

        &__button-search {
            width: 100%;
        }

        &__download-list {
            width: 100%;
            margin: 10px 0px;
        }

        &__button{

            &--solid {
                display: none;
            }
        }

        &__subtitle {
            
            &__content{
                display: none;

                &__text{
                    padding: 5px 15px;
                    justify-content: space-between;
                }
            }

            &__box{
                width: 100%;

                &__text {
                    @include global-typography($color-mine-shaft, bold, 12px);
                }

                &--mod{
                    flex-direction: row-reverse;
                }
            }

            &__container{
                padding: 5px 15px;
                justify-content: space-between;
            }
        }

        &__content-info {
            border: 0px;
        }

        &__actions{
    
            &__button{

                padding-right: 10px;
    
                &__edit{
                    flex-direction: column;
                    padding: 5px 10px 10px;
                }
            }
        }

    }
}