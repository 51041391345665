@import "../../../../../shared/styles/styles.scss";

.passenger-data{

    &__title{
        @include global-typography($color-mine-shaft,bold,16px,17px);
    }

    &__text{
        @include global-typography($color-dove-gray,normal,14px,15px);

        &__subtitle{
            @include global-typography($color-mine-shaft,normal,12px,13px);
            margin-right: 10px;
        }
    }

    &__copy{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    &__content{
        margin-bottom: 20px;
    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .passenger-data{

        &__text{
            &__subtitle{
                display: none;
            }
        }

        &__content{
            margin-bottom: 0px;
            padding-bottom: 20px;
            border-bottom: 1px solid $color-nobel;
        }

        &__tooltip{
            width: 213px;
            height: 146px;
            padding: 0;
            line-height: normal;
    
            &__header{

                width: 100%;
                padding: 5px;
                align-items: center;
                justify-content: space-evenly;
                border-bottom: 1px solid $color-box-shadow
            }
    
            &__body{
                display: flex;
                flex-flow: column wrap;
                height: 115px;
                width: 100%;
                padding: 5px 10px;
                text-align: center;
                justify-content: space-evenly;
                align-items: center
            }

            &__button{
                @include global-button-primary(34px, 100%)
            }

            &__icon{
                width: 30px;
            }
    
            &__title{
                @include global-typography($color-mine-shaft, bold, 14px, 15px);
            }
    
            &__text{
                width: 90%;
                @include global-typography($color-mine-shaft, normal, 14px, 15px);
            }
        }
    }
}


//Medium
@media screen and (min-width: $media-query-medium-min) {
    .passenger-data{
        
        &__dropdown{
            display: none;

            &__close{
                display: none;
            }
        } 

    }

}