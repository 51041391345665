@import "/src/shared/styles/styles.scss";

.sidebar-main{

    &__hidden{
        display: none;
    }

    &__img-profile{

        &.MuiSvgIcon-root{

            width: 50%;
            height: 80px;
        }

        &__content{
            margin-bottom: 30px;
            margin-top: 75px;
        }

        &__container{
            margin-top: 75px;
            margin-bottom: 30px;
        }
    }

    &__drawer{
        background: $color-white;
        width: 188px;
        flex-shrink: 0;
        white-space: nowrap;
        box-sizing: border-box;
        -webkit-transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        transition: width 225ms cubic-bezier(0.4,0,0.6,1) 0ms;
        overflow-x: hidden;

        &>div.MuiDrawer-paper{
            @include global-box-shadow;
            border: 0;
            z-index: 1;
            width: 188px;
            -webkit-transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
            transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
            overflow-x: hidden;
        }

        &--close{
            @extend .sidebar-main__drawer;
            width: 65px;

            &>div.MuiDrawer-paper{
                width: 65px;
            }
        }

        &__content{
            &.MuiPaper-root{
                z-index: 1;
            }
            &.MuiDrawer-root{
                z-index: 1;
            }
        }
    }
}