@import "../../../shared/styles/styles.scss";

.users-permissions {
    &__button{
        width: 200px;
        height: 60px;
        @include global-box-shadow(16px);
        background: $color-white;
        border-radius: 10px;

        &--mod {
            @include global-button-primary(25px);
            padding-left: 5px;
            font-size: 12px; 
        }

        &__text{
            @include global-typography($color-dove-gray, bold, 16px, 17px);
        }

        &__icon{
            margin-left: 15px;
            width: 30px;
            height: 30px;
        }
    }

    &__info {
        @include global-typography($color-mine-shaft, 700, 12px);
        padding-left: 5px;
    }

    &__select{
        width: 200px;
        &.ant-select{
            &>div.ant-select-selector{
                border: 1px solid $color-nobel;
                border-radius: 5px;
                height: 34px;
                box-shadow: none;
                cursor: pointer;
            }

            &.ant-select-focused {

                &.css-dev-only-do-not-override-ixblex {
                    &.ant-select-single{
                        &.ant-select-show-arrow {

                            &:hover{
                                &>div.ant-select-selector{
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &.ant-select-open {
                                &>.ant-select-selector {
                                    box-shadow: none;
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }
                    }
                }
            }

            &.css-dev-only-do-not-override-ixblex{

                &.ant-select-single{
                    &.ant-select-show-arrow{
                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-nobel;
                            }
                        }
                    }
                }
            }
        }
        &.ant-select.ant-select-status-error.ant-select-show-arrow{
            &>.ant-select-selector{
                border: 2px solid $color-lipstick;
            }
        }
    }

    &__input {
        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 34px;
            width: 90%;

            &--declared{
                width: 75%;
            }

            &>.ant-input-prefix{
                @include global-typography($color-mine-shaft,normal,10px)
            }
            
            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }
            
            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused{
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused{
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus{
                    box-shadow: none;
                }
            }
    
            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);
    
            }
        }

        &__reset{
            margin: 0px -2px 0px 0px; 
            height: 25px
        }

        &__search{
            margin: 0px 5px;
            height: 17px;
            width: 17px
        }
    }

    &__table{
        background: $color-white;
        padding-top: 10px;
        padding-bottom: 10px;
        @include global-box-shadow(30px);
        border-radius: 13px;
        margin-top: 2rem;

        &__line{
            border-bottom: 1px solid $color-nobel;
        }

        &__padding{
            padding: 5px;
            align-items: center;
        }

        &__title{
            @include global-typography($color-mine-shaft, bold, 12px, 13px);
        }

        &__text{
            @include global-typography($color-mine-shaft, normal, 12px, 11px);

            &__green{
                background-color: $color-citron;
                color: white;
                font-weight: bold;
                padding: 4px 28px ;
                border-radius: 10px;
                
            }

            &__red{
                background-color: $color-lipstick;
                color: white;
                font-weight: bold;
                padding: 4px 24px ;
                border-radius: 10px;
            }
        }
    }

    &__pagination {

        &.MuiPagination-root {
            border: 1px solid $color-nobel;
            border-radius: 5px;

            &>ul {

                &>li {
                    border-left: 1px solid $color-nobel;

                    &:first-of-type {
                        border-left: 0;
                    }
                }
            }
        }

        &__item {

            &.MuiButtonBase-root {
                margin: 0;
                padding: 0;
                border-radius: 0;
                min-width: 35px;
                height: 35px;
            }
        }

        &__content {
            width: 55%;
            justify-content: flex-end;
        }
    }

    &__content {

        &__navigation {
            padding-top: 10px;
        }
    }
}