@import "../../../shared/styles/styles.scss";

.summary{

    &__content{
        padding: 20px 10px;
        padding-bottom: 0;
    }

    &__subtitle{
        @include global-typography($color-mine-shaft,bold,12px,13px);
    }

    &__container{
        margin: auto 100px;

        &__header{
            margin: 10px auto;
        }
    }

    &__box{
        background: $color-white;
        @include global-box-shadow(30px);
        padding: 30px;
        border-radius: 13px;


        &__right{
            margin-top: 20px;
            margin-left: 10px;
            height: 49vh;
        }

        &__left{
            margin-top: 20px;
            margin-right: 5px;
            height: 49vh;
        }
    }

    &__title{
        @include global-typography($color-mine-shaft,bold,15px,16px);
    }

    &__label{
        @include global-typography($color-mine-shaft,bold,14px,15px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &--mod{
            @include global-typography($color-mine-shaft,bold,14px,15px);
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &--mod2{
            @include global-typography($color-mine-shaft,bold,14px,15px);
            padding-right: 10px;
            padding-bottom: 2px;
        }
    }

    &__text{
        @include global-typography($color-mine-shaft,normal,14px,15px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &__content{
            margin-bottom: 10px;

            &--mod{
                margin-bottom: 30px;
            }
        }
    }

    &__modal{

        &__text{
            @include global-typography($color-mine-shaft,normal,14px,15px);
        }

        &__button{
            @include global-button-primary(35px,100%)
        }
    }
}