@import "../../../../../shared/styles/styles.scss";

.create-user {
    &__content-left {
        @include global-box-shadow(30px);
        border-radius: 13px 13px 13px 13px;
        background-color: $color-white;

        &__image {
            width: 130px;
            height: 130px;

            &--mod {
                padding-bottom: 15px;
            }
        }

        &__box{
            padding: 20px;
        }

        &__button{
            @include global-button-primary(33px);
            padding: 10px;

            &--mod{
                border-radius: 40px;
                padding: 10px 0;
                width: 50px;
                height: 15px;
            }
        }

        &__line{
            width: 100%;
            height: 1px;
            background-color: #D6D6D6;
            margin: 8px 0;
        }

        &__text-state{
            @include global-typography($color-white, bold, 8px)
        }

        &__switch.ant-switch{
            border-radius: 10px;
            
            &.ant-switch-checked,
            &.ant-switch-checked:hover{
                background: $color-citron;
            }
        }
    }

    &__label {
        @include global-typography($color-mine-shaft,bold,14px,14px);
        padding-bottom: 10px;
        
        &--mod {
            font-size: 16px;
        }

        &--mod-text{
            font-size: 14px;
        }
    }

    &__content-right{
        @include global-box-shadow(30px);
        border-radius: 13px 13px 13px 13px;
        background-color: $color-white;
        margin-left: 15px;

        &__button{
            @include global-button-secondary(35px);
            padding: 10px;
        }
    }

    &__content{
        padding: 20px 10px;
    }

    &__subtitle{
        @include global-typography($color-mine-shaft,bold,12px,13px);
    }

    &__content-step{
        margin-bottom: 22px;

        &>#RFS-StepperContainer{
            width: 100%;
            padding: 7px 24px 12px 24px;


            &>#RFS-StepContainer{

                &>#RFS-StepMain{
                    flex-direction: column-reverse;
                }

                &>#RFS-ConnectorContainer{
                    top:75%;
                    left: calc((-50% + 1.5em) - 8px);
                    right: calc((50% + 1.5em) - 8px);
                }
            }
        }
    }

    &__steppers{
        &#RFS-StepperContainer{

            &>#RFS-StepContainer{

                &>#RFS-ConnectorContainer{

                    &>#RFS-Connector{
                        border-color: transparent;
                        &.active,
                        &.completed{
                            border: 1px solid $color-wild-willow;
                        }
                    }
                }
            }
        }
    }

    &__step{

        &#RFS-StepButton{
            background: $color-white;
            border: 1px solid $color-alto;

            &>span{
                @include global-typography($color-silver,600,14px,17px);
            }
        }

        &--active{

            &#RFS-StepButton{
                &~#RFS-LabelContainer{
                    &>#RFS-Label{
                        @include global-typography($color-dove-gray,bold,12px,13px);
                        margin-bottom: 5px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        &--inactive{
            &#RFS-StepButton{
                &~#RFS-LabelContainer{
                    &>#RFS-Label{
                        @include global-typography($color-silver,bold,12px,13px);
                        margin-bottom: 5px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    &__button-active{
        &#RFS-StepButton{
            background: $color-wild-willow;
            border: 0;

            &>span{
                @include global-typography($color-white,600,14px,17px);
            }
        }
    }
    
    &__text-alert{
        color: $color-citron;
        text-decoration: underline;

        &:hover{
            color: $color-citron;
        }
    }
}