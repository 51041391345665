@import "../../../shared/styles/styles.scss";

.invoice-management {
    
    &__container {
        height: 100%;
        width: 100%;
    }

    &__input {
        
        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 36px;
            width: 40%;

            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }

            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused {
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused {
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus {
                    box-shadow: none;
                }
            }

            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);

            }
        }

        &__search {
            margin: 0px 5px;
            height: 17px;
            width: 17px
        }
    }
    &__button{
        @include global-button-primary(35px);
        margin-left: 15px;


        &__text{
            @include global-typography($color-white, bold, 13px, 13px);
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    &__clear {
        border: 2px solid $color-wild-willow;
        border-radius: 5px;
        height: 34px;
        width: 34px;
        margin-left: 10px;
    }

    &__payment_type {
        width: 40%;
        height: 100px;
        @include global-box-shadow(40px);
        border-radius: 8px;
        margin-top: 10px;
        padding: 10px;
    }
    
    &__select {
        width: 65%;
        &__container {
            width: 100%;
            justify-content: space-between;
        }
    }
}