@import "../../shared/styles/styles.scss";

.tracking{

    &__container{
        background-image: url("../../assets/authentication/cover-page.png");
        height: 100vh;
    }

    &__content{
        background:  $color-white;
        width: 545px;
        height: 90%;
        border-radius: 13px 13px 0 0;
        @include global-box-shadow(30px);
        padding: 40px;
    }

    &__content-input{
        padding: 0px 2px 11px 2px;

        &>span {
            &.ant-input-affix-wrapper.client-data__input__content.ant-input-affix-wrapper-status-error {
                border: 2px solid $color-lipstick;
                
                &:hover{
                    border: 2px solid $color-lipstick;
                }
            }
        }

    }

    &__label{
        @include global-typography($color-mine-shaft,bold,13px,14px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 2px;
        padding-left: 7px;
    }

    &__button{
        @include global-button-primary(36px,100%);
        padding: 0 5px;
        margin-bottom: 5px;
    }

    &__box{
        border: 1px solid $color-nobel;
        border-radius: 5px;
        height: 360px;
        padding: 30px;
        margin-top: 40px;
        overflow-y: scroll;

        &::-webkit-scrollbar{
            overflow: hidden;
            width: 0px;
        }


        &__no-data{
            height: 90%;
        }

        &__data{
            margin-top: 40px;
        }
    }

    &__footer{
        height: 20%;
    }

    &__title{

        @include global-typography($color-mine-shaft,bold,15px,16px);

        &__content{
            margin-bottom: 30px;
        }
    }

    &__sub-title{
        @include global-typography($color-mine-shaft,bold,14px,15px);
    }

    &__steps{

        &.ant-steps>div.ant-steps-item{
            &>div.ant-steps-item-container{
                &>.ant-steps-item-tail{
                    padding: 13px 0 0;
                    &::after{
                        background-color: transparent;
                    }
                }

                &>.ant-steps-item-icon{
                    display: flex;
                    justify-content: center;

                    &>.ant-steps-icon{
                        width: auto;
                        height: auto;

                        &>div{
                            border: 1px solid $color-nobel;
                            height: 13px;
                            width: 13px;
                            border-radius: 100%;
                            opacity: 0.5;

                            &>div{
                                height: 70%;
                                width: 70%;
                                border-radius: 100%;
                                background: $color-nobel
                            }
                        }
                    }
                }

                &>.ant-steps-item-content{
                    &>.ant-steps-item-title{
                        line-height: 14px;
                        opacity: 0.3;
                    }
                }
            }
        }

        &.ant-steps>div.ant-steps-item-active,
        &.ant-steps>div.ant-steps-item-finish{
            &>div.ant-steps-item-container{
                &>.ant-steps-item-tail{
                    padding: 13px 0 0;
                }

                &>.ant-steps-item-icon{
                    display: flex;
                    justify-content: center;

                    &>.ant-steps-icon{
                        width: auto;
                        height: auto;

                        &>div{
                            border: 1px solid $color-citron;

                            &>div{
                                height: 70%;
                                width: 70%;
                                border-radius: 100%;
                                background: $color-citron
                            }
                        }
                    }
                }

                &>.ant-steps-item-content{
                    &>.ant-steps-item-title{
                        line-height: 14px;
                        opacity: 1;
                    }
                }
            }
        }

        &.ant-steps>div.ant-steps-item-active{
            &>div.ant-steps-item-container{
                &>.ant-steps-item-tail{
                    &::after{
                        background-color: transparent;
                    }
                }
            }
        }

        &.ant-steps>div.ant-steps-item-finish{
            &>div.ant-steps-item-container{
                &>.ant-steps-item-tail{
                    &::after{
                        background-color: $color-citron;
                    }
                }
            }
        }

        &__title{
            @include global-typography($color-mine-shaft,bold,14px);
        }

        &__description{
            @include global-typography($color-mine-shaft,normal,14px);
        }

    }

    &__errors{
        height: 10px;
        width: 90%;
        padding-left: 10px;
        padding-top: 5px;
        @include global-typography($color-mine-shaft, normal, 10px, 11px)
    }

    &__input {
        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 30px;
            width: 90%;
            
            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }
            
            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused{
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused{
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus{
                    box-shadow: none;
                }
            }
    
            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);
    
            }
        }
    }

    &__icon{
        width: 100%;
    }

    &__text{
        @include global-typography($color-mine-shaft,normal,12px,13px);
        text-align: center;
        margin-top: 10px;
    }
}

//Media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .tracking{

        &__content{
            height: 100%;
            width: 100%;
            border-radius: 0px;
            overflow: hidden;
        }

        &__box{
            margin-top: 15px;
            height: 55%;
        }

        &__footer{
            height: 15%;
        }

        &__input {
            &__content {
                width: 100%;
            }
        }

        &__content-input{
            padding-bottom: 25px;
        }

        &__steps{

            &__description{
                font-size: 11px;
            }
        }
    }
}