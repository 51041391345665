@import "../../../shared/styles/styles.scss";

.my-spreadsheets {

    &__header{
        margin-top: 20px;
    }

    &__title{
        @include global-typography($color-mine-shaft, bold, 16px, 20px);
    }

    &__label{
        @include global-typography($color-mine-shaft,bold,14px,15px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    &__body{

        &__content{
            margin-top: 25px;
        }

        &__box{
            padding-bottom: 10px;
        }

    }

    &__target{
        width: 98%;
        @include global-box-shadow(10px);
        background: $color-white;
        border-radius: 13px;
        padding: 30px;
        margin: 20px 1% 20px 1%;
        display: flex;
        flex-wrap: wrap;

        &__name{
            @include global-typography($color-mine-shaft, normal, 18px);

            &--bold{
                font-weight: bold;
            }
        }

        &__route{
            @include global-typography($color-mine-shaft, normal, 14px);

            &--bold{
                font-weight: bold;
            }
        }

        &__content{
            width: 100%;
            display: flex;
            justify-content: center;

            &__button{
                margin-top: 15px;
            }
        }

        &__button{
            @include global-button-primary(36px, 100%);

            &__text{
                @include global-typography($color-white, bold, 14px);

                &--black{
                    color: $color-mine-shaft;
                }
            }

            &__content{
                width: 100%;
                margin-top: 20px;
            }

            &__separator-right{
                border-right: 0.5px solid $color-nobel;
            }

            &__separator-left{
                border-left: 0.5px solid $color-nobel;
            }
        }

    }

    

}