@import "../../../../shared/styles/styles.scss";

.modal-save{
    
    &__text{
        @include global-typography($color-dove-gray,normal,12px,13px)
    }

    &__icon{
        padding-top: 50px;
        width: 155px;
    }

    &__title{
        @include global-typography($color-mine-shaft,bold,14px,15px);
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: center;
    }
}