@import "../../../../../../src/shared/styles/styles.scss";

.vehicle-stepper {

    &__content {
        margin-bottom: 22px;

        &>#RFS-StepperContainer {
            width: 100%;
            padding: 7px 24px 12px 24px;


            &>#RFS-StepContainer {

                &>#RFS-StepMain {
                    flex-direction: column-reverse;
                }

                &>#RFS-ConnectorContainer {
                    top: 75%;
                    left: calc((-50% + 1.5em) - 8px);
                    right: calc((50% + 1.5em) - 8px);
                }
            }
        }
    }

    &__steppers {
        &#RFS-StepperContainer {

            &>#RFS-StepContainer {

                &>#RFS-ConnectorContainer {

                    &>#RFS-Connector {
                        border-color: transparent;

                        &.active,
                        &.completed {
                            border: 1px solid $color-wild-willow;
                        }
                    }
                }
            }
        }
    }

    &__step {

        &#RFS-StepButton {
            background: $color-white;
            border: 1px solid $color-alto;

            &>span {
                @include global-typography($color-silver, 600, 14px, 17px);
            }
        }

        &--active {

            &#RFS-StepButton {
                &~#RFS-LabelContainer {
                    &>#RFS-Label {
                        @include global-typography($color-dove-gray, bold, 12px, 13px);
                        margin-bottom: 5px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        &--inactive {
            &#RFS-StepButton {
                &~#RFS-LabelContainer {
                    &>#RFS-Label {
                        @include global-typography($color-silver, bold, 12px, 13px);
                        margin-bottom: 5px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    &__button-active {
        &#RFS-StepButton {
            background: $color-wild-willow;
            border: 0;

            &>span {
                @include global-typography($color-white, 600, 14px, 17px);
            }
        }
    }

}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .vehicle-stepper {

        &__stepper {

            &__steppers {

                &#RFS-StepperContainer {

                    &>#RFS-StepContainer {
                        padding: 0;

                        &>#RFS-StepMain {

                            &>#RFS-LabelContainer {

                                &>#RFS-Label {
                                    white-space: normal;
                                    height: 25px;
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }

            }
        }
    }
}