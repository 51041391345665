@import "../../../shared/styles/styles.scss";

.my-orders {

    &__content{
        padding: 20px 10px;
        padding-bottom: 0;
        margin-bottom: 20px;
    }

    &__title {
        @include global-typography($color-mine-shaft, bold, 15px, 18px);
    }

    &__subtitle {
        @include global-typography($color-mine-shaft, bold, 12px, 14px);
    }

    &__target{
        background-color: $color-white;
        @include global-box-shadow(30px);
        width: 100%;
        border-radius: 10px;
        margin-bottom: 20px;
        padding: 20px;
    }

    &__header{
        margin-bottom: 20px;
    }

    &__text{
        @include global-typography($color-mine-shaft, normal, 12px, 14px);

        &__content{
            margin-bottom: 10px;
        }
    }

    &__button{
        @include global-button-primary(34px, 100%);

        &__text{
            margin-left: 5px;
        }

        &__content{
            margin-top: 20px;
        }

        &__secondary{
            @include global-button-secondary(34px, 100%);
            padding-right: 10px;
        }
    }

    &__hidden{
        display: none;
    }

    &__preview-img{
        width: 100%;
        height: 200px;
        border-radius: 6px;
        object-fit: contain;
    }
}