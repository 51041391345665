@import "../../../../../shared/styles/styles.scss";

.billing{

    &__title{
        @include global-typography($color-mine-shaft,bold,16px);

        &--mod{
            margin-top: 15px;
        }
    }

    &__label{
        @include global-typography($color-mine-shaft,bold,14px,15px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 15px;
        margin-bottom: 5px;
        padding: 0px 10px;
    }
    
    &__select {
        width: 95%;
    
        &.ant-select{
            &>div.ant-select-selector{
                border: 1px solid $color-nobel;
                border-radius: 5px;
                height: 34px;
                box-shadow: none;
                cursor: pointer;
            }

            &.ant-select-disabled{

                &>.ant-select-selector{
                    background-color: $color-mercury;
                    border: 2px solid $color-nobel;

                    &>.ant-select-selection-item{
                        @include global-typography($color-dove-gray,normal,14px)
                    }
                }
            }

            &.ant-select-focused {

                &.css-dev-only-do-not-override-ixblex {
                    &.ant-select-single.ant-select-show-arrow {

                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-wild-willow;
                            }
                        }

                        &.ant-select-open {
                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }

                        &>.ant-select-selector {
                            box-shadow: none;
                            border: 2px solid $color-wild-willow;
                        }
                    }
                }
            }

            &.css-dev-only-do-not-override-ixblex{

                &.ant-select-single{
                    &.ant-select-show-arrow{
                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-nobel;
                            }
                        }
                    }
                }
            }

            &.ant-select-status-error.ant-select-show-arrow {
                    &>.ant-select-selector {
                        border: 2px solid $color-lipstick;
                    }
                }
        }

        &--mod{
            width: 25%;
        }

        &--pay{
            width: 100%;
        }
    }

    &__input {
    
        height: 34px;
        border: 1px solid $color-nobel;
        border-radius: 5px;
        padding-right: 0px;
        width: 95%;

        &>.ant-input-suffix{

            &>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }
        }

        &.ant-input-affix-wrapper {

            &.billing-data__input.ant-input-affix-wrapper-status-error {
                border: 2px solid $color-lipstick;
        
                &:hover {
                    border: 2px solid $color-lipstick;
                }
            }

            &.ant-input-affix-wrapper-focused{
                border: 2px solid $color-sushi;
            }

            &:hover {
                border: 2px solid $color-nobel;
                box-shadow: none;

                &.ant-input-affix-wrapper-focused{
                    border: 2px solid $color-sushi;
                }
            }

            &:focus{
                box-shadow: none;
            }

        }

        &>input {
            height: 100%;
            box-shadow: none;
            margin: 0;
            background-color: transparent;
            @include global-typography($color-dove-gray, normal, 14px, 15px);

        }

        &__disabled{

            &:disabled{
                @include global-typography($color-mine-shaft,normal,14px,15px);
                background-color: $color-white;
                border: 2px solid $color-sushi;
                border-radius: 5px;
                margin-bottom: 0px;
                height: 34px;
                width: 95%;

                &:hover{
                    @include global-typography($color-mine-shaft,normal,14px,15px);
                    background-color: $color-white;
                    border: 2px solid $color-sushi;
                    border-radius: 5px;
                    margin-bottom: 0px;
                    height: 34px;
                    width: 95%;
                }
            }
        }


        &--mod{
            width: 70%;
        }

    }

    &__input-number{
        height: 34px;
        box-shadow: none;
        width: 84%;
        background: transparent;

        &__content{
            border: 1px solid $color-nobel;
            border-radius: 5px;
            height: 34px;

            &:hover{
                border: 2px solid $color-nobel;
            }

            &--disabled{
                border: 2px solid $color-nobel;
                background: $color-mercury;

                &>.ant-input-number{
                    &>.ant-input-number-input-wrap{
                        &>input{
                            @include global-typography($color-dove-gray,normal,14px)
                        }
                    }
                }
            }

        }

        &>.ant-input-number-handler-wrap{
            opacity: 1;
        }

        &__buttons{
            border-inline-start: 1px solid $color-nobel;
            width: 16%;
            height: 32px;
            justify-content: center;
            align-items: center;

            &__icon{
                margin-top: -3px;
            }
        }
    }

    &__line{
        width: 114%;
        background: $color-nobel;
        height: 1px;
        margin-top: 20px;
        margin-left: -35px;
    }

    &__clear{

        &__content{
            display: none;
        }
    }

    &__date{
        width: 90%;
        border: 1px solid $color-nobel;
        height: 34px;
        box-shadow: none;
        cursor: pointer;

        &:hover{
            border: 2px solid $color-pine-glade;
        }

        &.ant-picker-focused{
            border: 2px solid $color-pine-glade;
        }

        &.ant-picker-disabled{
            background: $color-mercury;
            border: 2px solid $color-nobel;

            &>.ant-picker-input{
                &>input{
                    @include global-typography($color-dove-gray,normal,14px,15px);
                }
            }
        }

        &__picker{
            &>.ant-picker-panel-container{
                &>.ant-picker-panel-layout{
                    &>.ant-picker-panel{
                        &>.ant-picker-date-panel{
                            &>.ant-picker-header{
                                &>.ant-picker-header-view{
                                    &>button{
                                        text-transform: capitalize;
                                        @include global-typography($color-dove-gray,bold,14px);
                                    }
                                }
                            }
                            &>.ant-picker-body{
                                &>.ant-picker-content{
                                    &>thead{
                                        &>tr{
                                            &>th{
                                                text-transform: uppercase;
                                                @include global-typography($color-mine-shaft,bold,14px,15px)
                                            }
                                        }
                                    }
                                    &>tbody{
                                        &>tr{
                                            &>td{
                                                &.ant-picker-cell-today{

                                                    &>div{
                                                        &::before{
                                                            border: 1px solid $color-citron;
                                                        }
                                                    }
                                                }

                                                &.ant-picker-cell-selected{
                                                    &>div{
                                                        background: transparent;
                                                        @include global-typography($color-citron,bold,14px);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &>.ant-picker-footer{
                            &>a{
                                @include global-button-secondary;
                                padding: 5px;
                            }
                        }
                        &>.ant-picker-month-panel{
                            &>.ant-picker-body{
                                &>.ant-picker-content{
                                    &>tbody{
                                        &>tr{
                                            &>td{
                                                &>div{
                                                    text-transform: capitalize;
                                                }
                                                &.ant-picker-cell-selected{
                                                    &>div{
                                                        background: $color-citron;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }

    }

    &__hour{
        width: 90%;
        border: 1px solid $color-nobel;
        border-radius: 5px;
        height: 34px;
        box-shadow: none;
        cursor: pointer;

        &:hover{
            border: 2px solid $color-wild-willow;
        }

        &.ant-picker-focused{
            border: 2px solid $color-wild-willow;
        }

        &__icon{
            width: 28px;
        }

        &.ant-picker-disabled{
            background: $color-mercury;
            border: 2px solid $color-nobel;

            &>.ant-picker-input{
                &>input{
                    @include global-typography($color-dove-gray,normal,14px,15px);
                }
            }
        }

        &__select{
            &>.ant-picker-panel-container{
                &>.ant-picker-panel-layout{
                    &>.ant-picker-panel{
                        &>.ant-picker-footer{
                            border-top: 1px solid $color-silver;
                            &>.ant-picker-ranges{
                                margin-left: 0;
                                &>.ant-picker-ok{
                                    &>button{
                                        @include global-button-primary(34px,100%);
                                    }
                                }

                                &>.ant-picker-now{
                                    @include global-button-secondary(34px);
                                    display: flex;
                                    align-items: center;

                                    &>a{
                                        padding: 10px;
                                        @include global-typography($color-citron,bold,14px);
                                    }
                                }
                            }
                        }
                        &>.ant-picker-time-panel{
                            &>.ant-picker-content{
                                &>.ant-picker-time-panel-column{
                                    &>.ant-picker-time-panel-cell-selected{
                                        &>.ant-picker-time-panel-cell-inner{
                                            background: $color-citron;
                                            @include global-typography($color-white,bold,14px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__comments{
        margin-left: 5px;

        &__label{
            @include global-typography($color-mine-shaft,normal,14px,30px);
        }
    }

    &__errors{
        height: 10px;
        width: 90%;
        padding-left: 10px;
        @include global-typography($color-lipstick, normal, 10px, 11px)
    }

    &__title-modal{
        @include global-typography($color-mine-shaft,bold,14px)
    }
    
    &__box{
        width: 90%;
    }
}