@import "../../../styles/styles.scss";

.success-toast{

    &__text{

        &>span{
            @include global-typography($color-dove-gray,bold,14px,15px)
        }
    }

    &__progress{
        background: $color-wild-willow;
        height: 7px;
    }
}