@import "../../shared/styles/styles.scss";

.shifts {
    &__breadcrumbs {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: flex-end;
        @include global-typography($color-mine-shaft, bold, 12px, 13px);
    }

    &__title{
        @include global-typography($color-mine-shaft, bold, 16px, 20px);
        padding-left: 10px
    }

    &__label{
        @include global-typography($color-mine-shaft, bold, 14px, 17px);
    }

    &__container{
        margin: 30px 100px 20px;

        &__header{
            margin: 10px auto;
        }
    }

    &__box{
        background: $color-white;
        @include global-box-shadow(30px);
        margin: 12px auto 20px auto;
        border-radius: 13px;

        &__header{
            padding: 20px;
        }

        &__week{
            @include global-typography($color-dove-gray, bold, 12px, 14px);
        }
    }

    &__calendar{
        padding: 20px 10px;
        height: 520px;

        &__container{
            overflow-y: scroll;
            height: 90%;

            &::-webkit-scrollbar{
                width: 0px;
            }
        }

        &__hour{
            @include global-typography($color-mine-shaft, bold, 10px, 12px);
            text-transform: capitalize;
            
            &__content{
                width: 50px;
                border-right: 1px solid $color-silver;
                border-bottom: 0px;
                border-left: 0px;
                min-height: 100px;
                background-color: $color-white;
                padding-left: 3px;

                &__active{
                    width: 50px;
                    background-color: $color-wild-willow;
                    border-right: 1px solid $color-silver;
                    border-bottom: 0px;
                    border-left: 0px;
                    min-height: 100px;
                }
            }

            &--mod {
                @include global-typography($color-mine-shaft, normal, 10px, 12px);
                margin-top: 2px;
            }

            &--exit{
                @include global-typography($color-dove-gray, bold, 10px, 12px);
                margin-top: 2px;
            }

            &__active {
                color: $color-white;
            }
        }

        &__days{
            width: calc( (100% - 50px) / 7);
            background: $color-gallery;
            border: 1px solid $color-silver;
            border-right: 0px;
            height: 30px;

            &:last-child{
                border-right: 1px solid $color-silver;
            }

            &--mod{
                background: $color-white;
                border-top: 0px;
            }

            &__hour{
                width: 50px;
                border: 1px solid $color-silver;
                border-right: 0px;

                &--mod{
                    border-top: 0px;
                    text-align: center;
                }
            }

            &__active {
                background-color: $color-wild-willow;
            }
        }

        &__vehicle{
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 6px 2px;
            margin-bottom: 8px;
            background-color: $color-white;
            align-items: center;
            @include global-typography($color-dove-gray,normal,10px,12px);

            &__icon-collapse{
                margin: 0 5px;
            }

            &__title{
                @include global-typography($color-dove-gray,bold,10px,12px);
            }
            
            &:hover, &:active{
                background-color: $color-beryl-green;
            }

        }

        &__droppable{
            display: flex;
            border: 1px solid lightgray; 
            border-right: 0px; 
            border-top: 0px;

            &__content {
                border: 1px solid lightgray;
                padding: 8px;
                width: calc( (100% - 50px) / 7);
                border-bottom: 0px;
                border-top: 0px;
                border-left: 0px;

                &__active{
                    border: 2px solid $color-wild-willow;
                    border-right: 3px solid $color-wild-willow;
                    padding: 8px;
                    width: calc( (100% - 50px) / 7);
                }
            }
        }

    }

    &__select-week{
        border: 1px solid $color-citron;
        height: 36px;
        border-radius: 5px;
        background: $color-white;
        width: 300px;

        &__date{
            width: 55%;
            height: 34px;
            box-shadow: none;
            cursor: pointer;
    
            &.ant-picker-disabled{
                background: $color-mercury;
                border: 2px solid $color-nobel;
    
                &>.ant-picker-input{
                    &>input{
                        @include global-typography($color-dove-gray,normal,14px,15px);
                    }
                }
            }
    
            &__picker{
                &>.ant-picker-panel-container{
                    &>.ant-picker-panel-layout>div{
                        &>.ant-picker-panel{
                            &>.ant-picker-week-panel{
                                &>.ant-picker-header{
                                    &>.ant-picker-header-view{
                                        &>button{
                                            text-transform: capitalize;
                                            @include global-typography($color-dove-gray,bold,14px);
                                        }
                                    }
                                }
                                &>.ant-picker-body{
                                    &>.ant-picker-content{
                                        &>thead{
                                            &>tr{
                                                &>th{
                                                    text-transform: uppercase;
                                                    @include global-typography($color-mine-shaft,bold,14px,15px)
                                                }
                                            }
                                        }
                                        &>tbody{
                                            &>tr{
                                                &.ant-picker-week-panel-row-selected{
                                                    &>td{
                                                        &::before{
                                                            background: $color-citron;
                                                        }
                                                    }
                                                }
                                                &>td{
                                                    &.ant-picker-cell-today{
    
                                                        &>div{
                                                            &::before{
                                                                border: 1px solid $color-citron;
                                                            }
                                                        }
                                                    }

                                                    &.ant-picker-cell-selected{
                                                        &>div{
                                                            background: transparent;
                                                            @include global-typography($color-citron,bold,14px);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &>.ant-picker-footer{
                                &>a{
                                    @include global-button-secondary;
                                    padding: 5px;
                                }
                            }
                            &>.ant-picker-month-panel{
                                &>.ant-picker-body{
                                    &>.ant-picker-content{
                                        &>tbody{
                                            &>tr{
                                                &>td{
                                                    &>div{
                                                        text-transform: capitalize;
                                                    }
                                                    &.ant-picker-cell-selected{
                                                        &>div{
                                                            background: $color-citron;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &>.ant-picker-year-panel{
                                &>.ant-picker-header{
                                    &>.ant-picker-header-view{
                                        &>button{
                                            &:hover{
                                                color: $color-citron;
                                            }
                                        }
                                    }
                                }
                                &>.ant-picker-body{
                                    &>.ant-picker-content{
                                        &>tbody{
                                            &>tr{
                                                &>td{
                                                    &.ant-picker-cell-selected{
                                                        &>div{
                                                            background: $color-citron;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
    
        }
    }

    &__input {
        width: 100%;
        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 35px;
            width: 60%;
            
            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }
            
            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused{
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused{
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus{
                    box-shadow: none;
                }
            }
    
            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);
    
            }
        }

        &__search{
            margin: 0px 5px;
            height: 17px;
            width: 17px;
        }

        &__button{
            @include global-button-primary(34px, 130px)
        }
    }

    &__modal-preview{

        &__title{
            @include global-typography($color-mine-shaft, bold, 14px);
            margin-top: 15px;
            text-align: center;
        }

        &__info{
            @include global-typography($color-mine-shaft, normal, 12px);
            text-align: center;
            width: 80%;
        }

        &__text{
            @include global-typography($color-mine-shaft, normal, 12px);
            text-align: center;
            width: 80%;
        }
    }

    &__modal{

        &__button{
            @include global-button-primary(34px, 100%);
        }
    }

    &__select {
        width: 20%;
        margin-bottom: 20px;
        margin-left: 15px;  
    }
}