@import "../../../../../shared/styles/styles.scss";

.modal-associate-driver {
    &__title {
        @include global-typography($color-mine-shaft, normal, 14px, 17px);
        margin: 20px 35px;
    }

    &__subtitle {
        @include global-typography($color-mine-shaft, bold, 14px, 17px);
        margin-left: 5px;
    }

    &__text {
        @include global-typography($color-mine-shaft, normal, 14px, 17px);
    }

    &__input {
        width: 100%;
        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 35px;
            margin-bottom: 10px;

            &--mod {
                background: $color-mercury;
                @extend .modal-associate-driver__input__content;
            }
            
            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }
            
            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused{
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused{
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus{
                    box-shadow: none;
                }
            }
    
            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);
    
            }
        }

        &__search{
            margin: 0px 5px;
            height: 17px;
            width: 17px
        }
    }

    &__comments{
        @extend .modal-associate-driver__subtitle;
        // margin-top: 30px;
        width: 100%;
        display: flex;
    }

    &__text-area{

        &.ant-input{
            resize: none;
            @include global-typography($color-dove-gray,normal,12px);
            border-color: $color-nobel;
            box-shadow: none;
            margin-top: 5px;
            margin-bottom: 20px;

            &:focus,
            &:hover:focus{
                border: 2px solid $color-sushi;
            }

            &:hover{
                border: 2px solid $color-nobel;
            }
        }
    }

}