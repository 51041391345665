@import "../../../shared/styles/styles.scss";

.constants-file {

    &__general{
        max-width: 1200px;
    }
    
    &__box{
        background: $color-white;
        @include global-box-shadow(30px);
        margin: 12px auto 20px auto;
        border-radius: 13px;
        
        &--mod{
            padding: 15px
        }
    }
    
    &__subtitle{
        @include global-typography($color-mine-shaft, bold, 16px, 30px);
        white-space: nowrap;
        height: 30px;
        text-align: center;
    }
    
    &__content{
        max-height: 600px;
        overflow-y: scroll;
    }

    &__container{
        margin-bottom: 6px;
    }

    &__input {
        width: 100%;
        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 30px;
            width: 90%;
            
            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused{
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused{
                        border: 2px solid $color-sushi;
                    }
                }
                &:focus{
                    box-shadow: none;
                }
            }
            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);
            }
        }
    }

    &__button{
        &__actions {
            @include global-button-primary(25px, 90px);
            font-size: 12px;
        } 
    }

    &__text{
        font-weight: bold;
    }

    &__content-base-unit {
        width: 100%;
        padding-right: 5px;

        &__text {
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &__line{
        border-bottom: 1px solid $color-nobel;
        margin-top: 6px;
    }

    &__input{
        margin-bottom: 5px;
    }

}