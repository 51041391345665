@import "../../../../../../src/shared/styles/styles.scss";

.owner-information {

    &__content {
        padding: 0px 2px 11px 2px;

        &__button {
            margin-bottom: 75px;
        }

        &>span {
            &.ant-input-affix-wrapper.owner-information__input__content.ant-input-affix-wrapper-status-error {
                border: 2px solid $color-lipstick;

                &:hover {
                    border: 2px solid $color-lipstick;
                }
            }
        }

        &__right {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
        }
    }

    &__label {
        @include global-typography($color-mine-shaft, bold, 13px, 14px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 2px;
        padding-left: 7px;

        &__icon {
            margin: 2px 0 0 3px;
            width: 6px;
            height: 6px;
            border-radius: 6px;
            background-color: $color-lipstick;
        }

        &--mod {
            width: 90%;
        }
    }

    &__option-copy{
        height: 14px;
        display: flex;
        margin-top: -7px;
        align-items: center;
        justify-content: center;

        &__text{
            margin-right: 6px;
            @include global-typography($color-dove-gray,normal,10px,11px);
        }

        &__icon{
            width: 20px;
            height: 20px;
        }
    }

    &__select {
        width: 90%;

        &.ant-select {
            &>div.ant-select-selector {
                border: 1px solid $color-nobel;
                border-radius: 5px;
                height: 30px;
                box-shadow: none;
                cursor: pointer;
            }

            &.ant-select-focused {
                &.css-dev-only-do-not-override-ixblex {
                    &.ant-select-single {
                        &.ant-select-show-arrow {
                            &:hover {
                                &>div.ant-select-selector {
                                    border: 2px solid $color-wild-willow;
                                }
                            }
                            &.ant-select-open {
                                &>.ant-select-selector {
                                    box-shadow: none;
                                    border: 2px solid $color-wild-willow;
                                }
                            }
                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }
                    }
                }
            }

            &.css-dev-only-do-not-override-ixblex {
                &.ant-select-single {
                    &.ant-select-show-arrow {
                        &:hover {
                            &>div.ant-select-selector {
                                border: 2px solid $color-nobel;
                            }
                        }
                    }
                }
            }
        }

        &.ant-select.ant-select-status-error.ant-select-show-arrow {
            &>.ant-select-selector {
                border: 2px solid $color-lipstick;
            }
        }
        &.indicative-number {
            width: 28%;
        }
    }

    &__input {

        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px 22px 3px 3px;
            height: 30px;
            width: 90%;

            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }

            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused {
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused {
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus {
                    box-shadow: none;
                }
            }

            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);

            }

            &.phone-number{
                width: 70%;
            }
        }
    }

    &__hidden {
        display: none;
    }

    &__upload-photo {
        width: 90%;
        height: 170px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        border-radius: 13px;
        border: 1px solid $color-nobel;

        &:hover {
            border: 2px solid $color-nobel;
        }

        &__photo-uploaded {
            width: 60px;
            height: 60px;
            border-radius: 8px;
        }

        &__charge-photo-label {
            display: flex;
            width: 180px;
            height: 34px;
            cursor: pointer;
            border-radius: 5px;
            align-items: center;
            flex-direction: row;
            justify-content: space-evenly;
            background: $color-citron;

            &__text {
                font-size: 14px;
                font-weight: bold;
                color: $color-white;
            }

            &__disabled {
                cursor: not-allowed;
                background-color: rgba(0, 0, 0, 0.10);
            }
        }

        &__text {
            width: 75%;
            margin-top: 6px;
            text-align: center;
            @include global-typography($color-dove-gray, normal, 8px);
        }
    }

    &__errors {
        height: 10px;
        width: 90%;
        padding-left: 10px;
        @include global-typography($color-lipstick, normal, 10px, 11px)
    }

}