@import "../../../shared/styles/styles.scss";

.settings {

    &__breadcrumbs {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: flex-end;
        @include global-typography($color-mine-shaft, bold, 12px, 13px);
    }

    &__tabs {
        @include global-typography($color-mine-shaft, normal, 14px);

        &.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #85AF26;
        }

        &.ant-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn {
            color: #85AF26;
        }

        &.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
            @include global-typography($color-mine-shaft, bold, 14px);
        }

        &.ant-tabs .ant-tabs-ink-bar {
            background-color: #85AF26; 
        }
    }

}