@import "../../../../../../src/shared/styles/styles.scss";

.structures-of-vehicles {

    &__container {
        padding: 5px;
        border-radius: 10px;
        background-size: cover;
        border: 2px solid $color-mine-shaft;
    }

    &__row{
        justify-content: flex-end;
        flex: 1;
        
        &__seat{
            flex: 1;
            width: 100%;
            display: flex; 
            justify-content: flex-end;
        }

        &__driver-seat{
            display: flex;
            justify-content: flex-end;
            width: 100%;
            flex: 1;
        }

        &--driver{
            justify-content: flex-end;
            flex: 1.4;
        }
    }

}