@import "../../shared/styles/styles.scss";

.boarding-sheet {
    &__breadcrumbs {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: flex-end;
        @include global-typography($color-mine-shaft, bold, 12px, 13px);
    }

    &__title{
        @include global-typography($color-mine-shaft, bold, 16px, 20px);
    }

    &__container{
        margin: 30px 100px 20px;

        &__header{
            margin: 10px auto;
            justify-content: space-between;
        }
        
        &--mod{
            min-height: 42%;
        }
    }

    &__box{
        background: $color-white;
        @include global-box-shadow(30px);
        margin: 12px auto 20px auto;
        border-radius: 13px;

        &--mod{
            padding: 10px;
            padding-right: 45px
        }
    }

    &__subtitle{
        @include global-typography($color-mine-shaft, bold, 12px, 1
        0px);
        overflow-x: hidden;
        text-align: center;

        &__hour {
            @extend .boarding-sheet__subtitle;
            margin-top:20px;
        }

        &__content{
            padding: 10px 5px;
            height: 50px;
            justify-content: center;

            &__text{
                padding: 5px;
                justify-content: center;
            }
        }
    }

    &__content-actions-button {
        margin: auto
    }

    &__text{
        @include global-typography($color-mine-shaft, normal, 11px, 30px);
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 30px;
    }

    &__line{
        height: 1px;
        background: $color-nobel;
        margin: 0;
        max-width: 100%;
    }

    &__info {
        @include global-typography($color-mine-shaft, 700, 12px);
        padding-left: 5px;
    }

    &__pagination {

        &.MuiPagination-root {
            border: 1px solid $color-nobel;
            border-radius: 5px;

            &>ul {

                &>li {
                    border-left: 1px solid $color-nobel;

                    &:first-of-type {
                        border-left: 0;
                    }
                }
            }
        }

        &__item {

            &.MuiButtonBase-root {
                margin: 0;
                padding: 0;
                border-radius: 0;
                min-width: 35px;
                height: 35px;
            }
        }

        &__content {
            width: 55%;
            justify-content: flex-end;
        }

    }

    &__modal{

        &__button{
            @include global-button-primary(34px, 100%);

            &__mod{
                margin-top: 20px;
            }
        }

        &__buttons{
            @include global-button-secondary(34px, 20%);
        }
    }

    &__input {
        width: 100%;
        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 35px;
            width: 90%;
            
            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }
            
            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused{
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused{
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus{
                    box-shadow: none;
                }
            }
    
            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);
    
            }
        }

        &__search{
            margin: 0px 5px;
            height: 17px;
            width: 17px
        }
    }

    &__button{
        @include global-button-primary(34px, 90px);

        &--hour{
            height: 18px;
            font-size: 8px;
            margin-right: 7px;
        }

        &__icon{
            height: 11px;
            margin-right: 3px
        }

        &__transfer{
            @include global-button-secondary(34px, 100px);
            display: flex;
            line-height: normal;

            &__spin {
                .ant-spin-dot-item {
                    background-color: $color-sushi; 
                }
            }

            &:disabled {
                color: $color-citron;
                background: $color-white;
                border: 2px solid $color-citron;
                cursor: not-allowed;
            }
        }

        &--mod{
            width: 40%;
            height: 28px;
            font-size: 8px;
            font-weight: bold;
        }

        &--warning{
            @include global-button-warning(34px, 90px);
            width: 40%;
            height: 28px;
            font-size: 8px;
            font-weight: bold;
        }

        &--download-all-pdf {
            width: 180px;
            align-items: center;
            justify-content: center;
        }

        &__img{
            margin-right: 5px;
        }

        &__text{
            margin-right: 9px;
        }

        &__width{
            width: 20%;
        }
    }

    &__select-week{
        border: 1px solid $color-citron;
        height: 36px;
        border-radius: 5px;
        background: $color-white;

        &__date{
            width: 55%;
            height: 34px;
            box-shadow: none;
            cursor: pointer;
    
            &.ant-picker-disabled{
                background: $color-mercury;
                border: 2px solid $color-nobel;
    
                &>.ant-picker-input{
                    &>input{
                        @include global-typography($color-dove-gray,normal,14px,15px);
                    }
                }
            }
    
            &__picker.ant-picker-dropdown{
                &>.ant-picker-panel-container{
                    &>.ant-picker-panel-layout>div{
                        &>.ant-picker-panel{
                            &>.ant-picker-date-panel{
                                &>.ant-picker-header{
                                    &>.ant-picker-header-view{
                                        &>button{
                                            text-transform: capitalize;
                                            @include global-typography($color-dove-gray,bold,14px);
                                        }
                                    }
                                }
                                &>.ant-picker-body{
                                    &>.ant-picker-content{
                                        &>thead{
                                            &>tr{
                                                &>th{
                                                    text-transform: uppercase;
                                                    @include global-typography($color-mine-shaft,bold,14px,15px)
                                                }
                                            }
                                        }
                                        &>tbody{
                                            &>tr{
                                                &>td{
                                                    &.ant-picker-cell-today{
    
                                                        &>div{
                                                            &::before{
                                                                border: 1px solid $color-citron;
                                                            }
                                                        }
                                                    }
    
                                                    &.ant-picker-cell-selected{
                                                        &>div{
                                                            background: transparent;
                                                            @include global-typography($color-citron,bold,14px);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &>.ant-picker-month-panel{
                                &>.ant-picker-body{
                                    &>.ant-picker-content{
                                        &>tbody{
                                            &>tr{
                                                &>td{
                                                    &>div{
                                                        text-transform: capitalize;
                                                    }
                                                    &.ant-picker-cell-selected{
                                                        &>div{
                                                            background: $color-citron;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &>.ant-picker-year-panel{
                                &>.ant-picker-body{
                                    &>.ant-picker-content{
                                        &>tbody{
                                            &>tr{
                                                &>td{
                                                    &.ant-picker-cell-selected{
                                                        &>div{
                                                            background: $color-citron;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &>.ant-picker-footer{
                            &>.ant-picker-ranges{
                                margin: 0px;
                                &>.ant-picker-now>a{
                                    @include global-button-secondary;
                                    padding: 5px;
                                }
                            }
                        }
    
                    }
                }
            }
    
            &>.ant-picker-input{
                &>input{
                   cursor: pointer;
                }
            }
        }
    }

    &__separator{
        height: 1px;
        background: $color-nobel;
        width: 100%;
        margin: 10px 0px;
    }

    &__generate{
        height: 180px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0px;
        }
    }

    &__range-picker {
        border: 1px solid $color-nobel;
        border-radius: 5px;
        height: 34px;
        box-shadow: none;
        width: 100%;

        &__img {
            height: 25px;
        }

        &__modal {
            &>.ant-picker-range-wrapper {
                &>.ant-picker-panel-container {
                    &>.ant-picker-panel-layout {
                        &>div {
                            &>.ant-picker-panels {
                                &>.ant-picker-panel {
                                    &>.ant-picker-date-panel {
                                        &>.ant-picker-header {
                                            &>.ant-picker-header-view {
                                                &>.ant-picker-month-btn {
                                                    text-transform: capitalize;
                                                }
                                            }
                                        }

                                        &>.ant-picker-body {
                                            &>.ant-picker-content {
                                                &>thead {
                                                    &>tr {
                                                        &>th {
                                                            text-transform: uppercase;
                                                            @include global-typography($color-mine-shaft, bold, 14px, 15px);
                                                        }
                                                    }
                                                }

                                                &>tbody {
                                                    &>tr {
                                                        &>.ant-picker-cell-today {
                                                            &>.ant-picker-cell-inner {
                                                                @include global-typography($color-citron, bold, 14px);

                                                                &::before {
                                                                    border: 0px;
                                                                }
                                                            }
                                                        }

                                                        &>.ant-picker-cell-range-start.ant-picker-cell-in-view,
                                                        &>.ant-picker-cell-range-end.ant-picker-cell-in-view {
                                                            background: $color-citron;
                                                            border-radius: 4px;

                                                            &>.ant-picker-cell-inner {
                                                                background: $color-citron;
                                                                @include global-typography($color-white, bold, 14px);
                                                            }

                                                            &::before {
                                                                background: $color-citron;
                                                            }
                                                        }

                                                        &>.ant-picker-cell-in-view.ant-picker-cell-in-range {
                                                            border-top: 1px dashed $color-citron;
                                                            border-bottom: 1px dashed $color-citron;
                                                            background: $color-beryl-green;

                                                            &::before {
                                                                background: $color-beryl-green;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &>.ant-picker-month-panel {
                                        &>.ant-picker-body {
                                            &>.ant-picker-content {
                                                &>tbody {
                                                    &>tr {
                                                        &>td {
                                                            text-transform: capitalize;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.ant-picker {
            &:hover {
                border: 2px solid $color-pine-glade;
            }

            &.ant-picker-focused {
                border: 2px solid $color-citron;

                &>.ant-picker-active-bar {
                    background: transparent;
                }
            }
        }
    }

    &__countability {
        font-weight: bold;
        font-size: 14px;
    }
    
    &__spin {
        .ant-spin-dot-item {
            background-color: $color-white; 
        }
    }

    &__text-alert{
        color: $color-citron;
        text-decoration: underline;

        &:hover{
            color: $color-citron;
        }
    }

    &__text-alert-title{
        font-weight: bold;
    }

}