/* Global Styles */

// VARIABLES

//Media queries
$media-query-small-max: 639.09px;
$media-query-medium-min: 640px;
$media-query-medium-max: 1023.09px;
$media-query-large-min: 1024px;
$media-query-large-max: 1199.09px;
$media-query-x-large-min: 1200px;

// Colors
$color-silver: #BFBFBF;
$color-dove-gray: #707070;
$color-mine-shaft: #2C2C2C;
$color-wild-willow: #B1CB79;
$color-citron: #85AF26;
$color-trendy-green: #6E931B;
$color-lipstick: #C40062;
$color-white: #FFFFFF;
$color-alabaster: #F8F8F8;
$color-gallery: #EDEDED;
$color-mercury: #E4E4E4;
$color-nobel: #B7B7B7;
$color-box-shadow:#00000029;
$color-alto: #E0E0E0;
$color-beryl-green: #DBE8C0;
$color-sycamore: #769234;
$color-sushi: #7ba532;
$color-pine-glade: #B6CA82;
$color-lipstick-course : #C400621C;
$color-wild-sand: #F5f5f5;

//FUNCTIONS

// Typography
@mixin global-typography($color:null,$weight:null,$size:null,$line-height:null) {
    font-style: normal;
    font-variant: normal;
    // font-family: "Atkinson Hyperlegible";
    letter-spacing: 0px;
    opacity: 1;
    @if $weight {
        font-weight: $weight;
    }
    @if $line-height {
        line-height: $line-height;
    }
    @if $size {
        font-size: $size;
    }
    @if $color {
        color: $color;
    }
}

// Buttons
@mixin global-button-primary($height:auto,$width:auto) {
    background: $color-citron;
    border-radius: 5px;
    height: $height;
    width: $width;
    @include global-typography($color-white,700,14px);

    
    &:hover{
        background: $color-wild-willow;
    }
    
    &:active{
        background: $color-trendy-green;
    }
    
    &:disabled{
        background: $color-silver;
    }
    

}

@mixin global-button-secondary($height:auto,$width:auto) {

    background: $color-white;
    border-radius: 5px;
    height: $height;
    width: $width;
    border: 2px solid $color-citron;
    @include global-typography($color-citron,bold,14px,15px);

    
    &:hover{
        background: $color-beryl-green;
        color:$color-citron;
    }
    
    &:active{
        background: $color-white;
        color:$color-trendy-green;
        border: 2px solid $color-sycamore;
    }
    
    &:disabled{
        background: $color-mercury;
        color: $color-dove-gray;
        border: 2px solid $color-silver;
    }
}

@mixin global-button-pink($height:auto,$width:auto) {

    background: $color-white;
    border-radius: 5px;
    height: $height;
    width: $width;
    border: 2px solid $color-lipstick;
    @include global-typography($color-mine-shaft,bold,14px,15px);

    
    &:hover{
        background: $color-lipstick-course;
        color: $color-lipstick;
    }
    
    &:active{
        background: $color-white;
        color: $color-lipstick;
        border: 2px solid $color-lipstick;
    }
    
    &:disabled{
        background: $color-mercury;
        color: $color-dove-gray;
        border: 2px solid $color-silver;
    }
}

@mixin global-button-warning($height:auto,$width:auto) {
    background: #e7a125;
    border-radius: 5px;
    height: $height;
    width: $width;
    @include global-typography($color-white,700,14px);

    
    &:hover{
        background: #e7a125;
    }
    
    &:active{
        background: #e7a125;
    }
    
    &:disabled{
        background: $color-silver;
    }
    

}

@mixin global-box-shadow($intensity : 10px) {
    box-shadow: 0px 0px $intensity $color-box-shadow;
}