@import "../../../shared/styles/styles.scss";

.sales {
    &__breadcrumbs {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: flex-end;
        @include global-typography($color-mine-shaft, bold, 12px, 13px);
    }

    &__content {
        width: 100%;
        padding: 0 80px;
    }

    &__welcome {
        width: 100%;
        text-align: center;
        @include global-typography($color-mine-shaft, normal, 16px, 17px);
    }

    &__title{
        width: 100%;
        text-align: center;
        @include global-typography($color-mine-shaft,bold,18px,19px);
        padding: 30px;
    }

    &__type-sales {
        width: 100%;
        padding: 30px 70px;
        justify-content: space-evenly;

        &__card {
            position: relative;
            width: 30%;
            height: 110px;
            border-radius: 12px;
            background: $color-white;
            border: 2px solid transparent;
            @include global-box-shadow(16px);
            @include global-typography($color-dove-gray,bold,16px);

            transition: border .2s;

            &:hover {
                border: 2px solid $color-nobel;
            }

            &__active{
                border: 2px solid $color-wild-willow;

                &:hover {
                    border: 2px solid $color-sushi;
                }
            }

            &__icon{
                width: 60px;
                transition: all .2s;
                
                &--hover{
                    width: 67px;
                    transition: all .2s;
                }
            }

            &__add{
                position: absolute;
                bottom: -18px;
                display: flex;
                align-items: center;
                justify-content: center;

                transition: bottom .2s;
                
                &--hover{
                    @extend .sales__type-sales__card__add;
                    bottom: -21px;
                }
            }
    
        }

    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .sales{
        &__content {
            padding: 0;
            overflow-y: scroll;
        }

        &__info-content{
            height: 92vh;
            margin-bottom: 10px;
            overflow: hidden;
        }

        &__welcome{
            margin-top: 21px;
            width: 80%;
            display: flex;
            align-self: center;
        }

        &__title{
            padding: 10px;
        }

        &__type-sales {
    
            &__card {
                width: 100%;
            }
        }
    }
}