@import "../../../../../shared/styles/styles.scss";

.order-guide{

    position: relative;
    width: 100%;
    height: 93vh;
    background-color: $color-white;
    border-radius: 13px 0 0 13px;
    align-items: center;
    @include global-box-shadow(30px);


    &__header {
        width: 100%;
        height: 55px;
        margin-left: 3px;
        padding: 0 0 15px 0;
        border-bottom: .5px solid $color-dove-gray;

        &__title {
            justify-content: center;
            align-items: flex-end;
            @include global-typography($color-mine-shaft, bold, 16px, 17px)
        }
    }

    &__content {
        min-height: 50%;
        width: 100%;
        padding: 10px 38px;

        &__field {
            @include global-typography($color-mine-shaft, normal, 14px, 15px);

            &__label {
                @include global-typography($color-mine-shaft, bold, 14px, 15px)
            }
        }
    }

    &__passages {
        height: calc(100% - 404px);
        overflow-y: scroll;
        width: 100%;
        background-color: $color-alabaster;
        padding: 10px 30px;

        &__select {
            margin-bottom: 10px;

            &__status {
                width: 12px;
                height: 12px;
                border-radius: 15px;
                border: .5px solid $color-mine-shaft;
                margin-right: 5px;

                transition: border .2s;

                &--active {
                    @extend .order-guide__passages__select__status;
                    border: 3px solid $color-citron;

                }
            }

            &__text {
                @include global-typography($color-mine-shaft, bold, 12px, 13px)
            }
        }

        &__ticket {
            width: 100%;
            height: 113px;
            background-color: $color-white;
            border-radius: 5px;
            margin-bottom: 10px;
            padding: 8px 3px;
            border: 2px solid transparent;
            @include global-box-shadow(12px);
            transition: border .2s;

            &__header {
                height: 30px;
                width: 100%;
                padding: 0 5px;
                justify-content: space-between;
                border-bottom: 1px solid $color-nobel;
            }

            &__body {
                padding: 3px 20px;
                height: calc(100% - 30px);
            }

            &__labels {
                @include global-typography($color-mine-shaft, bold, 12px, 13px);
                justify-content: space-evenly;
                height: 100%;
                margin-right: 15px
            }

            &__data {
                @include global-typography($color-mine-shaft, normal, 12px, 13px);
                justify-content: space-evenly;
                height: 100%;
            }

            &--active {
                @extend .order-guide__passages__ticket;
                border: 2px solid $color-citron;
            }
        }
    }

    &__footer {
        position: relative;
        bottom: 0;
        width: 100%;
        height: 97px;
        padding: 10px 30px;
        border-radius: 0 0 0 13px;

        &__link {
            @include global-typography($color-citron, normal, 12px, 13px);
        }

        &__button {
            @include global-button-primary(34px, 100%);
            margin-bottom: 7px
        }
    }

    &__steps{

        &.ant-steps>div.ant-steps-item{
            &>div.ant-steps-item-container{
                &>.ant-steps-item-tail{
                    padding: 13px 0 0;
                    &::after{
                        background-color: $color-citron;
                    }
                }

                &>.ant-steps-item-icon{
                    display: flex;
                    justify-content: center;

                    &>.ant-steps-icon{
                        width: auto;
                        height: auto;
                    }
                }

                &>.ant-steps-item-content{
                    &>.ant-steps-item-title{
                        line-height: 14px;
                    }
                }
            }
        }

        &__title{
            @include global-typography($color-mine-shaft,bold,14px);
        }

        &__description{
            @include global-typography($color-mine-shaft,normal,14px);
        }

        &__circle{

            height: 70%;
            width: 70%;
            border-radius: 100%;
            background: $color-citron;

            &__content{
                height: 13px;
                width: 13px;
                border-radius: 100%;
                border: 1px solid $color-citron;
            }
        }
    }

    &__box{
        height: 35%;
        background: $color-alabaster;
        width: 100%;
        padding: 30px 40px;
    }
}