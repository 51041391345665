@import "../../../shared/styles/styles.scss";

.invoicing-resolution {

    &__button{
        width: 325px;
        height: 60px;
        @include global-box-shadow(16px);
        background: $color-white;
        border-radius: 10px;

        &__text{
            @include global-typography($color-dove-gray, bold, 16px, 17px);
        }

        &__icon{
            margin-left: 15px;
            width: 30px;
            height: 30px;
        }
    }

    &__table{
        background: $color-white;
        padding-top: 10px;
        padding-bottom: 10px;
        @include global-box-shadow(30px);
        border-radius: 13px;
        margin-top: 2rem;

        &__line{
            border-bottom: 1px solid $color-nobel;
        }

        &__title{
            @include global-typography($color-mine-shaft, bold, 12px, 13px);
        }

        &__text{
            @include global-typography($color-mine-shaft, normal, 10px, 11px);

            &__green{
                color: $color-citron;
                font-weight: bold;
            }

            &__red{
                color: $color-lipstick;
                font-weight: bold;
            }
        }
    }
}