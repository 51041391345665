@import "../../../../../../src/shared/styles/styles.scss";

.link-driver-vehicle {

    &__content-search {
        margin-bottom: 20px;
    }

    &__content-link-vehicle-header {
        display: flex;
        position: relative;
    }

    &__title {
        margin-left: 8px;
        @include global-typography($color-mine-shaft, bold, 14px, 17px);
    }

    &__unlink-vehicle-button{
        right: 0;
        display: flex;
        position: absolute;
        align-items: center;
        @include global-button-primary(34px);

        &__text {
            margin: 0 10px;
        }
    }

    &__label {
        padding-left: 7px;
        margin-bottom: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include global-typography($color-mine-shaft, bold, 14px, 17px);
    }

    &__input {

        &__content {
            width: 96%;
            height: 34px;
            padding: 3px 24px 3px 3px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;

            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }

            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused {
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused {
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus {
                    box-shadow: none;
                }
            }

            &>input {
                margin: 0;
                height: 100%;
                box-shadow: none;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);
            }
        }

        &__search {
            margin: 0px 5px;
            height: 17px;
            width: 17px
        }
    }

    &__button-search {
        width: 100%;
        height: 34px;
        cursor: pointer;
        border-radius: 5px;
        background-color: $color-citron;

        &__text {
            font-size: 14px;
            font-weight: bold;
            color: $color-white;
        }
    }

    &__vehicle-link-box {
        height: 400px;
        border-radius: 5px;
        border: 1px solid $color-nobel;

        &__content-info {
            width: 100%;
            height: 88%;
            display: flex;
            align-items: center;
            flex-direction: column;
            // justify-content: center;
        }

        &__information-descriptive-vehicle {
            height: 50%;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 20px 30px 0 30px
        }

        &__content-box {
            display: flex;
            flex-direction: row;

            &__first {
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                border-right: 1px solid $color-nobel;
            }

            &__second {
                border: none;
                justify-content: flex-end;
                @extend .link-driver-vehicle__vehicle-link-box__content-box__first;
            }

            &__content-titles {
                width: 45%;

                &__title {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    @include global-typography($color-mine-shaft, bold, 12px, 20px);
                }
            }

            &__content-texts {
                width: 50%;

                &__text {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    @include global-typography($color-mine-shaft, normal, 12px, 20px);
                }
            }
        }

        // TODO: 283580 Implement operation card

        // &__content-href {
        //     margin-top: 4px
        // }

        // &__details-link {
        //     border-bottom:  1px solid $color-mine-shaft;
        //     @include global-typography($color-mine-shaft, normal, 12px, 20px);
        // }

        // &__vehicle-files {
        //     height: 50%;
        //     width: 100%;
        //     display: flex;
        //     padding: 0 30px;
        //     flex-direction: row;
        //     justify-content: space-between;

        //     &__content-box {
        //         width: 32%;
        //         height: 100%;
        //         display: flex;
        //         flex-direction: column;
        //         justify-content: space-between
        //     }

        //     &__content-title {
        //         height: 55px;
        //         display: flex;
        //         flex-direction: row;
        //     }

        //     &__title {
        //         width: 130px;
        //         @include global-typography($color-mine-shaft, bold, 13px, 13px);

        //         &--mod {
        //             width: 100px;
        //             @extend .link-driver-vehicle__vehicle-link-box__vehicle-files__title
        //         }

        
        //         &__icon {
        //             width: 6px;
        //             height: 6px;
        //             border-radius: 6px;
        //             margin: 2px 0 0 3px;
        //             background-color: $color-lipstick;
        //         }
        //     }

        //     &__content-card {
        //         flex: auto;
        //         width: 100%;
        //         padding: 5px 20px;
        //         border-radius: 5px;
        //         overflow: scroll;
        //         border: 2px solid $color-citron;
        //     }

        //     &__card {
        //         width: 100%;
        //         height: 45px;
        //         margin: 8px 0;
        //         border-radius: 5px;
        //         display: flex;
        //         flex-direction: row;
        //         align-items: center;
        //         justify-content: space-around;
        //         border: 2px solid $color-alto;
        //     }

        //     &__photo {
        //         height: 90%;
        //         width: 50px;
        //         border-radius: 3px;
        //     }

        //     &__text {
        //         overflow: hidden;
        //         white-space: nowrap;
        //         text-overflow: ellipsis;
        //         @include global-typography($color-mine-shaft, normal, 10px, 12px);
        //     }
        // }

        &__search-results-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
        }

        &__box-info {
            width: 100%;
            height: 100%;
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
        }

        &__description {
            width: 56%;
            text-align: center;
            @include global-typography($color-mine-shaft, bold, 18px, 22px);
        }

        &__content-button {
            height: 12%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &__button{
                @include global-button-primary(34px, 25%);
            }
        }
    }

    &__content-modal {
        width: 100%;
        height: 400px;

        &__photo {
            width: 100%;
            height: 80%;
            border-radius: 10px;
            object-fit: cover;
        }
    }
}