@import "../../../../../../shared/styles/styles.scss";

.seat-map-render {
    &__container {
        border: 2px solid $color-mine-shaft;
        border-radius: 10px;
        padding: 5px;
        margin-right: 10px;
        background-size: cover;
    }

    &__tooltip{
        width: 142px;

        &__header {
            justify-content: space-between;
            border-bottom: .5px solid $color-dove-gray;
            padding-bottom: 2px;
        }

        &__body{
            padding-top: 3px;
            justify-content: space-evenly;
            
            &__button{
                @include global-button-primary(34px, 100%)
            }
        }
        &__name{
            @include global-typography($color-mine-shaft, bold, 12px, 13px)
        }
        &__status{
            @include global-typography($color-mine-shaft, normal, 10px, 11px)
        }
        &__description{
            width: 100%;
            align-items: center;
            justify-content: space-evenly;
            margin-bottom: 10px;

            &__text{
                text-align: center;
                @include global-typography($color-citron, bold, 14px, 15px);

                &--mod{
                    @include global-typography($color-mine-shaft, bold, 14px, 15px)
                }
            }

        }
        
    }

    &__row{
        justify-content: flex-end;
        flex: 1;
        
        &__seat{
            flex: 1;
            width: 100%;
            display: flex; 
            justify-content: flex-end;
            
        }

        &__driver-seat{
            display: flex;
            justify-content: flex-end;
            width: 100%;
            flex: 1;
        }

        &--driver{
            justify-content: flex-end;
            flex: 1.4;
        }
    }
}