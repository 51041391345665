@import "../../../../shared/styles/styles.scss";

.stepper{

    &__box{
        min-width: 600px;
    }

    &__header{
        justify-content: space-between;
    }

    &__subtitle{
        @include global-typography($color-mine-shaft,bold,12px,13px);
    }

    &__title-icon{
        height: 35px;
        width: 47px;
    }

    &__title{
        @include global-typography($color-mine-shaft,bold,16px,17px);
        margin-left: 2%;
    }

    &__close-text{
        @include global-typography($color-mine-shaft,bold,14px,17px);
    }

    &__stepper{
        &__content{
            background: $color-white;
            height: 95px;
            @include global-box-shadow(30px);
            margin: 12px auto 20px auto;
            border-radius: 13px;

            &>#RFS-StepperContainer{
                width: 100%;
                padding: 7px 24px 12px 24px;


                &>#RFS-StepContainer{

                    &>#RFS-StepMain{
                        flex-direction: column-reverse;
                    }

                    &>#RFS-ConnectorContainer{
                        top:75%;
                        left: calc((-50% + 1.5em) - 8px);
                        right: calc((50% + 1.5em) - 8px);
                    }
                }
            }
        }

        &__step{

            &#RFS-StepButton{
                background: $color-white;
                border: 1px solid $color-alto;

                &>span{
                    @include global-typography($color-silver,600,14px,17px);
                }
            }

            &--active{

                &#RFS-StepButton{
                    &~#RFS-LabelContainer{
                        &>#RFS-Label{
                            @include global-typography($color-dove-gray,bold,12px,13px);
                            margin-bottom: 5px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }

            &--inactive{
                &#RFS-StepButton{
                    &~#RFS-LabelContainer{
                        &>#RFS-Label{
                            @include global-typography($color-silver,bold,12px,13px);
                            margin-bottom: 5px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }

        &__button-active{
            &#RFS-StepButton{
                background: $color-wild-willow;
                border: 0;

                &>span{
                    @include global-typography($color-white,600,14px,17px);
                }
            }
        }

        &__steppers{
            &#RFS-StepperContainer{

                &>#RFS-StepContainer{

                    &>#RFS-ConnectorContainer{

                        &>#RFS-Connector{
                            border-color: transparent;
                            &.active,
                            &.completed{
                                border: 1px solid $color-wild-willow;
                            }
                        }
                    }
                }
            }
        }
    }

}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {

    .stepper{
        
        &__title,
        &__title-icon{
            display: none;
        }

        &__box{
            min-width: auto;
            height: 160px;
        }


        &__close-text{
            font-size: 12px;
            line-height: 13px;
        }

        &__stepper{

            &__steppers{
    
                &#RFS-StepperContainer{
    
                    &>#RFS-StepContainer{
                        padding: 0;
    
                        &>#RFS-StepMain{
    
                            &>#RFS-LabelContainer{
    
                                &>#RFS-Label{
                                    white-space: normal;
                                    height: 25px;
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
    
            }
        }
    }
}

//Medium
@media screen and (min-width: $media-query-medium-min) {
    .stepper{

        &__hidden{
            display: none;
        }
    }
}