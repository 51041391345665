@import '../../../shared/styles/styles.scss';

.new-password-page {
    &__container {
        align-items: center;
        justify-content: center;
        background-color: $color-white;
        border-radius: 13px;
        padding: 30px;
        width: 440px;
        height: 719px;
        @include global-box-shadow(32px)
    }

    &__form-content {
        width: 100%;
        justify-content: space-around;

        &__title {
            text-align: center;
            @include global-typography($color-mine-shaft, bold, 18px, 19px);
        }

        &__field {
            width: 100%;
            justify-content: space-between;
            padding-bottom: 50px;

            &__title {
                padding-left: 15px;
                @include global-typography($color-mine-shaft, bold, 14px, 15px);

                &__icon {
                    margin: 2px 0 0 3px;
                    width: 6px;
                    height: 6px;
                    border-radius: 6px;
                    background-color: $color-lipstick;
                }
            }

            &__description {
                padding-left: 15px;
                padding-top: 5px;

                &__text {
                    @include global-typography($color-dove-gray, normal, 10px, 11px);
                    
                    &--mod {
                        @include global-typography($color-citron, normal, 10px, 11px);
                    }
                }
            }
            
            &__input {
                display: flex;
                align-items: center;
                margin: 5px 0;
                border-radius: 5px;
                border-color: $color-nobel;
                height: 34px;
                padding: 4px 0px 4px 15px;
                outline: none;
                @include global-typography($color-mine-shaft, bold, 14px, 15px);
                
                &>.ant-input {
                    box-shadow: none;
                    height: 24px;
                    margin-bottom: 0;
                }
                
                &.ant-input-affix-wrapper {
                    &.ant-input-affix-wrapper-focused {
                        border: 2px solid $color-citron;
                        box-shadow: none;
                    }
                    
                    &:hover {
                        border: 2px solid $color-nobel;
                        box-shadow: none;
                        
                        &.ant-input-affix-wrapper-focused {
                            border: 2px solid $color-citron;
                        }
                    }
                }
                
                &>.ant-input-suffix>.ant-input-clear-icon {
                    margin: 0;
                }
                
                &--mod {
                    border: 2px solid $color-citron;
                }
                
                &__error{
                    padding-left: 15px;
                    @include global-typography($color-lipstick, bold, 10px, 11px);
                }
                
                &__success{
                    padding-left: 15px;
                    @include global-typography($color-mine-shaft, normal, 14px, 15px);
                }

            }

        }

        &__button {
            @include global-button-primary(34px, 100%);
            margin-bottom: 20px;
            ;

            &[type=submit] {
                border-radius: 5px;
            }
        }


        &__return {
            @include global-typography($color-citron, normal, 16px, 17px);

            &:hover {
                color: $color-sycamore
            }
        }
    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .new-password-page {
        &__container {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
    }
}