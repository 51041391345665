@import "../../../../../shared/styles/styles.scss";

.seat-assignment{
    &__content{
        margin-top: 111px;
        width: 100%;
        min-height: 500px;
        padding-left: 3px;
        background-color: $color-white;
        border-radius: 13px 0 0 13px;
        align-items: center;
        @include global-box-shadow(30px);
    }
    
    &__header{
        width: 100%;
        height: 67px;
        padding: 0 0 20px 0;
        border-bottom: .5px solid $color-dove-gray;
        
        &__title{
            justify-content: center;
            align-items: flex-end;
            @include global-typography($color-mine-shaft, bold, 16px, 17px)
        }
    }
    
    &__body{
        width: 100%;
        padding: 20px 35px;
        
        &__filed{
            height: 30px;
            margin-bottom: 20px;
            
            &__title{
                @include global-typography($color-mine-shaft, bold, 14px, 15px);
                margin-bottom: 2px;
            }
            
            &__description{
                @include global-typography($color-mine-shaft, normal, 14px, 15px)
                
            }
        }
    }
    
    &__footer{
        width: 100%;
        height: 130px;
        padding-bottom: 30px;
        border-top: .5px solid $color-dove-gray;
        justify-content: space-evenly;

        &__price{
            @include global-typography($color-citron, bold, 16px, 17px)
        }
        
        &__button{
            @include global-button-primary(34px, 209px)
        }
        
        &__cancel{
            @include global-typography($color-citron, bold, 14px, 15px)
        }

    }
    
    &--active{
        @extend .seat-assignment;
        right: 0;
    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .seat-assignment {

        &__content {
            width: 80%;
            min-height: 60%;
            border-radius: 13px;
            margin-top: 0px;
        }

        &--active {
            @extend .seat-assignment;
            height: 88vh;
            width: 101vw;
            z-index: 2;
            justify-content: center;
            align-items: center;
            display: flex;
        }
    }
}
