@import "../../../shared/styles/styles.scss";

.turn{

    &__content{
        padding: 20px 10px;
        padding-bottom: 0;
    }

    &__container{
        padding-top: 10px;
        justify-content: flex-end;
        width: 100%;
        transition:  width .2s;
        
        &--mod{
            @extend .turn__container;
            width: calc(100% - 300px);
            transition:  width .3s;
        }

        &__steps{
            width: 100%;
            height: 100%;
            padding: 0 15%;
            transition: padding .2s;
            
            &--mod{
                width: 100%;
                height: 100%;
                padding: 0 2% 0 15%;
                transition: padding .3s;
            }
        }
    }

    &__drawer-modal{
        position: fixed;
        padding-top: 47px;
        top: 8px;
        right: -310px;
        width: 310px;

        transition: right .4s;
        
        &--active{
            @extend .turn__drawer-modal;
            right: 0;
        }
    }

    &__subtitle{
        @include global-typography($color-mine-shaft,bold,12px,13px);
    }

    &__modal{

        &__button{
            @include global-button-primary(34px,100%);

            &__label{
                @include global-typography($color-white,bold,14px,15px);
            }
        }

        &__spin {
            .ant-spin-dot-item {
                background-color: $color-sushi; 
            }
        }

        &__close{
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;

            &__label{
                @include global-typography($color-citron,normal,14px,15px);
            }
        }
    }

    &__text-alert{
        color: $color-citron;
        text-decoration: underline;

        &:hover{
            color: $color-citron;
        }
    }

}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .turn{

        &__content{
            display: none;
        }

        &__container {
            height: 100%;
            align-items: flex-start;
        
            &__steps{
                padding: 0;
                &--mod {
                    padding: 0;
                }
            }
        
            &--mod {
                width: 100%;
            }
        }

        &__drawer-modal {
            display: none;
            background-color: rgba($color: $color-box-shadow, $alpha: .8);
            padding: 0;
            top: 0;
            left: 0;
            width: 0;

            transition: none;

            &--active {
                @extend .turn__drawer-modal;
                height: 88vh;
                width: 101vw;
                z-index: 2;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }

    }
}

//Medium
@media screen and (min-width: $media-query-medium-min) {
    .turn{
        &__hidden{
            display: none;
        }
        
    }

}