@import "../../../../../../shared/styles/styles.scss";

.passenger-seat {
    justify-content: space-between;

    &__field {
        width: 26%;
        padding: 0 5px;
        margin-top: 10px;

        &--mod {
            width: 13%;
            margin-top: 10px;
        }

        &--phone {
            width: 30%;
            padding: 0 5px;
            margin-top: 10px;
        }
    }

    &__label {
        @include global-typography($color-mine-shaft, bold, 14px, 15px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        margin: 0 0 5px 10px;
    }

    &__input {
        height: 34px;
        border: 1px solid $color-nobel;
        border-radius: 5px;
        padding-right: 0px;

        &__phone {
            display: flex;
            flex: 1;
            padding-left: 5px;
        }

        &>.ant-input-suffix {

            &>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }
        }

        &.ant-input-affix-wrapper {

            &.passenger-seat__input.ant-input-affix-wrapper-status-error {
                border: 2px solid $color-lipstick;

                &:hover {
                    border: 2px solid $color-lipstick;
                }
            }

            &.ant-input-affix-wrapper-focused {
                border: 2px solid $color-sushi;
            }

            &:hover {
                border: 2px solid $color-nobel;
                box-shadow: none;

                &.ant-input-affix-wrapper-focused {
                    border: 2px solid $color-sushi;
                }
            }

            &:focus {
                box-shadow: none;
            }

        }

        &>input {
            height: 100%;
            box-shadow: none;
            margin: 0;
            background-color: transparent;
            @include global-typography($color-dove-gray, normal, 14px, 15px);

        }

        &__disabled {

            &:disabled {
                @include global-typography($color-mine-shaft, bold, 14px, 15px);
                background-color: $color-mercury;
                border: 2px solid $color-nobel;
                border-radius: 5px;
                margin-bottom: 0px;
                height: 34px;
            }
        }
    }

    &__dropdown{
        display: none;

        &__close{
            display: none;
        }
    } 

    &__tooltip{
        width: 142px;
        padding: 5px 10px;
        line-height: normal;

        &__header{
            width: 100%;
        }

        &__body{
            display: none;
        }

        &__title{
            @include global-typography($color-mine-shaft, bold, 10px, 11px);
        }
    }

    &__copy {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-bottom: 10px;
    }

    &__errors {
        height: 10px;
        width: 90%;
        padding-left: 10px;
        @include global-typography($color-lipstick, normal, 10px, 11px)
    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .passenger-seat{

        &__field {
            width: 100%;
            margin-top: 10px;
    
            &--mod {
                width: 100%;
                padding: 0 5px;
                margin-top: 10px;
            }
    
            &--phone {
                width: 100%;
                margin-top: 10px;
            }
        }
        &__copy {
            width: 100%;
        }

        &__dropdown{
            display: flex;
            justify-content: flex-end;

            &__hidden{
                margin: 0;
                height: 0px;
                overflow: hidden;
            }

            &__close{
                display: block;
                margin-left: 7px;

                &__content{
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 22px;
                }
            }

            &__icon{
                border: 2px solid $color-citron;
                border-radius: 5px;
                height: 34px;
            }

            &__img{
                height: 20px;
                width: 30px;
            }
        }

        &__tooltip{
            width: 213px;
            height: 146px;
            padding: 0;
            line-height: normal;
    
            &__header{

                width: 100%;
                padding: 5px;
                align-items: center;
                justify-content: space-evenly;
                border-bottom: 1px solid $color-box-shadow
            }
    
            &__body{
                display: flex;
                flex-flow: column wrap;
                height: 115px;
                width: 100%;
                padding: 5px 10px;
                text-align: center;
                justify-content: space-evenly;
                align-items: center
            }

            &__button{
                @include global-button-primary(34px, 100%)
            }

            &__icon{
                width: 30px;
            }
    
            &__title{
                @include global-typography($color-mine-shaft, bold, 14px, 15px);
            }
    
            &__text{
                width: 90%;
                @include global-typography($color-mine-shaft, normal, 14px, 15px);
            }
        }
    }
}