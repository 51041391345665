@import '../../shared/styles/styles.scss';

.authentication-router {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-image: url("../../assets/authentication/cover-page.png");
    background-size: cover;
    padding: 45px 80px;
    height: 100vh;
}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .authentication-router{
        padding: 0;
    }
}