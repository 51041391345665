@import "../../../../../shared/styles/styles.scss";

.table-no-sales{

    &__content-data{
        border-bottom: 1px solid $color-silver;
        justify-content: space-evenly;

        &--mod{
            border: 0;
        }
    }

    &__text{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 5px 0px 5px 10px;
        @include global-typography($color-mine-shaft,400,10px);
    }

    &__title{
        @include global-typography($color-mine-shaft,700,12px);
    }

    &__content{
        height: 29vh;
    }

    &__sorry{
        margin: 10px 30px 0 30px;
        @include global-typography($color-mine-shaft,bold,18px,19px);
    }

}

//Media queries

//Small

@media screen and (max-width: 639px) {
    .table-no-sales{

        &__content-data{
            border: 0;
        }

        &__text{
            padding: 0 20px 0 5px;
            max-width: 180px;
            &__content{
                display: none;
                justify-content: space-between;

                &--mod{
                    margin-top: 15px;
                }
            }
        }

        &__title{
            border: 0;
            padding-left: 20px;
            padding-right: 5px;
        }

    }
}

//Large

@media screen and (min-width: 640px) {
    .table-no-sales{

        &__text{
    
            &__content{
                display: flex;
                flex-flow: column nowrap;
            }
        }

    }
}