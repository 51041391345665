@import "../../../../../shared/styles/styles.scss";

.modal-service-delivery {
    &__subtitle{
        @include global-typography($color-mine-shaft, bold, 13px, 30px);
        
        height: 30px;
        text-align: left;
        display: flex;
        justify-content: space-between;
    }

    &__value{
        font-weight: normal ;
        text-align: right;
    }

    &__errors{
        height: 10px;
        width: 90%;
        padding-left: 10px;
        @include global-typography($color-lipstick, normal, 10px, 11px)
    }

    &__select {
        width: calc(100% - 45px);

        &__container {
            width: 20%;
        }

        &--mod {
            width: 100%;
        }
    }

    &__modal-electronic{

        &__title{
            @include global-typography($color-mine-shaft, bold, 14px);
            margin-top: 15px;
            text-align: center;
        }

        &__info{
            @include global-typography($color-mine-shaft, normal, 12px);
            text-align: center;
            width: 80%;
        }

        &__text{
            @include global-typography($color-dove-gray, bold, 12px);
            text-align: center;
            width: 80%;
        }

        &__button{
            @include global-button-primary(34px,100%);

            &__label{
                @include global-typography($color-white,bold,14px,15px);
            }
        }

        &__close{
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;

            &__label{
                @include global-typography($color-citron,normal,14px,15px);
            }
        }
    }
    &__input {
        &__container {
            width: 33%;
            padding: 0 10px;
        }

        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 36px;

            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }

            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused {
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused {
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus {
                    box-shadow: none;
                }
            }

            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);

            }
        }

        &__reset {
            margin: 0px -2px 0px 0px;
            height: 25px
        }

        &__search {
            margin: 0px 5px;
            height: 17px;
            width: 17px
        }
    }
}