@import "../../shared/styles/styles.scss";

.sales-router{
    &__content{
        flex-grow: 1;
        padding: 24px;
        padding-top: 55px;
        height: 100vh;
    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .sales-router{
        &__content{
            height: 90vh;
            width: 100%;
        }
    }
}