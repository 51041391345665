@import "../../../shared/styles/styles.scss";

.add-vehicle {

    &__container {
        padding: 20px 10px;
        padding-bottom: 0;
    }

    &__subtitle {
        @include global-typography($color-mine-shaft, bold, 12px, 13px);
    }

    &__content {
        width: 100%;
        height: 100%;
        padding: 10px 130px 0 130px;
        transition: width .2s;
    }

    &__content-items {
        height: 40px;

        &__item {
            height: 100%
        }

        &__icon {
            height: 100%
        }

        &__title {
            @include global-typography($color-mine-shaft, 700, 16px);
        }

        &__button-close {
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        &__close-text {
            font-size: 14px;
            font-weight: bold;
            color: $color-mine-shaft;
        }
    }

    &__box {
        width: 100%;
        border-radius: 13px;
        background: $color-white;
        padding: 25px 35px 0 35px;
        margin: 6px auto 20px auto;
        @include global-box-shadow(30px);
    }

    &__content-button {
        padding: 20px 0;
    }

    &__button {
        width: 110px;
        height: 34px;
        font-size: 14px;
        font-weight: bold;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__next {
            padding: 5px;
            display: flex;
            margin-left: 10px;
            align-items: center;
            @include global-button-secondary(34px, 110px)
        }

        &__edit {
            @include global-button-primary(34px, 120px);
            @extend .add-vehicle__button;

            &__icon {
                margin-left: 5px;
            }
        }

        &__save {
            margin-left: 10px;
            color: $color-white;
            background: $color-citron;
            @extend .add-vehicle__button;
        }

        &__save-disabled {
            margin-left: 10px;
            cursor: not-allowed;
            background: rgba(0, 0, 0, 0.10);
            @extend .add-vehicle__button;
        }

        &__return {
            color: $color-citron;
            background: $color-white;
            border: 2px solid $color-citron;
            @extend .add-vehicle__button;
        }

        &__icon {
            height: 100%;
            width: 20%;
        }
    }

}