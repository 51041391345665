@import "../../../../../shared/styles/styles.scss";

.recipients-data{

    &__title{
        @include global-typography($color-mine-shaft,bold,16px,17px);
    }

    &__label{
        @include global-typography($color-mine-shaft,bold,13px,14px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 2px;
        padding-left: 7px;

        &__icon {
            margin: 2px 0 0 3px;
            width: 6px;
            height: 6px;
            border-radius: 6px;
            background-color: $color-lipstick;
        }

        &--mod{
            width: 90%;
        }
    }

    &__content{
        padding: 0px 2px 11px 2px;

        &>span {
            &.ant-input-affix-wrapper.client-data__input__content.ant-input-affix-wrapper-status-error {
                border: 2px solid $color-lipstick;
                
                &:hover{
                    border: 2px solid $color-lipstick;
                }
            }
        }

        &__right{
            justify-content: flex-end;
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__input {
        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 30px;
            width: 90%;
            
            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }
            
            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused{
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused{
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus{
                    box-shadow: none;
                }
            }
    
            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);
    
            }

            &.phone-number{
                width: 70%;
            }
        }

        &__reset{
            margin: 0px -2px 0px 0px; 
            height: 25px
        }

        &__search{
            margin: 0px 5px;
            height: 17px;
            width: 17px
        }
    }

    &__select{
        width: 90%;
        &.ant-select{
            &>div.ant-select-selector{
                border: 1px solid $color-nobel;
                border-radius: 5px;
                height: 30px;
                box-shadow: none;
                cursor: pointer;
            }

            &.ant-select-focused {

                &.css-dev-only-do-not-override-ixblex {
                    &.ant-select-single{
                        &.ant-select-show-arrow {

                            &:hover{
                                &>div.ant-select-selector{
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &.ant-select-open {
                                &>.ant-select-selector {
                                    box-shadow: none;
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }
                    }
                }
            }

            &.css-dev-only-do-not-override-ixblex{

                &.ant-select-single{
                    &.ant-select-show-arrow{
                        &:hover{
                            &>div.ant-select-selector{
                                border: 2px solid $color-nobel;
                            }
                        }
                    }
                }
            }
        }
        &.ant-select.ant-select-status-error.ant-select-show-arrow{
            &>.ant-select-selector{
                border: 2px solid $color-lipstick;
            }
        }

        &.indicative-number{
            width: 28%;
        }
    }

    &__errors{
        height: 10px;
        width: 90%;
        padding-left: 10px;
        @include global-typography($color-lipstick, normal, 10px, 11px)
    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .recipients-data{
        &__hidden{
            display: none;
        }

        &__input{

            &__content{
                width: 100%;
            }
        }

        &__select{
            width: 100%;
        }

        &__label{

            &--mod{
                width: 100%;
            }
        }
    }
}
