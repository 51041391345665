@import "../../../../shared/styles/styles.scss";

.navigation-body{

    &__content{
        padding: 20px 10px;
        padding-bottom: 0;
    }

    &__container{
        padding-top: 10px;
        justify-content: flex-end;
        width: 100%;
        transition:  width .2s;
        
        &--mod{
            @extend .navigation-body__container;
            width: calc(100% - 300px);
            transition:  width .3s;
        }

        &__steps{
            width: 100%;
            height: 100%;
            padding: 0 15%;
            transition: padding .2s;
            
            &--mod{
                width: 100%;
                height: 100%;
                padding: 0 2% 0 15%;
                transition: padding .3s;
            }
        }
    }


    &__box{
        min-width: 600px;
    }

    &__footer{
        @include global-box-shadow(30px);
        border-radius: 13px 13px 0 0;
        height: 64vh;
        background: $color-white;


        &__content{
            padding: 25px 35px 20px 35px;
            height: 84%;
            overflow-y: scroll;
            overflow-x: hidden;

            &::-webkit-scrollbar{
                overflow: hidden;
                width: 0px;
            }

            &--mod{
                @extend .navigation-body__footer__content;
                height: calc( 84% - 60px );
            }
        }

        &__container{
            border-top: 1px solid $color-silver;
            height: 16%;
            justify-content: space-between;
            align-items: center;
            padding: 0px 35px;

            &--mod{
                @extend .navigation-body__footer__container;
            }
        }

        &__button{
            @include global-button-secondary(34px,105px);
            text-align: center;

            &__save{
                @include global-button-primary(34px,100px);
            }

            &__box{
                display: flex;
                flex:1;
                padding-right: 2%;
                justify-content: flex-end;
            }

            &--mod{
                margin-right: 3%;
                width: 40%;
            }
        }

        &__total{
            height: 60px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0px 35px;
            border-top: 1px solid $color-nobel;

            &__text{
                @include global-typography($color-mine-shaft,bold,16px,17px);
            }
        }

        &--mod{
            @include global-box-shadow(30px);
            border-radius: 13px 13px 0 0;
            height: 64vh;
            background: $color-white;
        }

        &__check{
            height: 60px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 35px;
            border-top: 1px solid $color-nobel;
            //TODO: implement checkbox
            visibility: hidden;

            &>.ant-checkbox-wrapper,
            &>.ant-checkbox-wrapper:hover{

                &>.ant-checkbox{
                    &>.ant-checkbox-inner{
                        border: 1px solid $color-dove-gray;
                    }

                    &.ant-checkbox-checked{

                        &>span.ant-checkbox-inner{
                            background: $color-wild-willow;
                            border: 1px solid $color-wild-willow;
                        }

                        &::after{
                            border-color: transparent;
                        }
                    }
                }

                &>span{
                    @include global-typography($color-dove-gray,normal,14px,15px);
                }
            }
        }

        &__invoice{
            display: flex;
        }
    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .navigation-body{

        &__content{
            display: none;
        }

        &__container {
            height: 100%;
            align-items: flex-start;
        
            &__steps{
                padding: 0;
                &--mod {
                    padding: 0;
                }
            }
        
            &--mod {
                width: 100%;
            }
        }


        &__box{
            min-width: auto;
            height: 160px;
        }

        &__footer{
            height: 75%;

            &__container{
                margin: auto -23px;
                background: $color-white;
                @include global-box-shadow(30px);
                padding: 0px 22px;
                position: fixed;
                bottom: 0;
                width: 100%;
                height: 80px;
                z-index: 2;

                &--mod{
                    border-top: 1px solid $color-silver;
                    justify-content: space-between;
                    align-items: center;
                    margin: auto -23px;
                    background: $color-white;
                    padding: 0px 22px;
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                    height: 80px;
                    box-shadow: none;
                    z-index: 2;
                }

                &--invoice{
                    padding: 22px 22px;
                    height: 17%;
                    align-items: flex-end;
                }
            }

            &__button{

                &__box{
                    justify-content: center;
                    padding: 0;
                }

                &--mod{
                    position: absolute;
                    top: 7%;
                    margin-right: 0;
                    width: 90%;
                }
            }

            &__content{
                padding: 24px 15px 140px 15px;
                height: 100%;
            }

            &__total{
                z-index: 2;
                height: 45px;
                justify-content: center;
                position: fixed;
                margin: auto -23px;
                background: $color-white;
                bottom: 80px;
                @include global-box-shadow(30px);
            }

            &--mod{
                height: 75%;
                border-radius: 13px 13px 0 0;
                background: $color-white;
                @include global-box-shadow(30px);
            }
            
            &__check{
                display: none;
            }

            &__invoice{
                display: flex;
                flex-flow: wrap-reverse;
            }
        }
    }
}

//Medium
@media screen and (min-width: $media-query-medium-min) {
    .navigation-body{

        &__footer{
            &__check{
                &__content{
                    @include global-box-shadow(30px);
                    border-radius: 13px 13px 0 0;
                    height: 64vh;
                    background: $color-white;
                }
            }

            &__box{
                padding: 25px 20px 20px 35px;
                height: calc( 84% - 60px );
                overflow-y: scroll;
            }
        }

    }

}