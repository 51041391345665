@import "../../../../../shared/styles/styles.scss";

.permits-user {

    &__subtitle{
        @include global-typography($color-mine-shaft,bold,14px,16px);

        &--mod{
            margin-left: 10px;
        }
    }

    &__text{
        @include global-typography($color-mine-shaft,normal,10px,12px);
    }

    &__content-boarding-sheet{
        margin-top: 10px;
    }

    &__switch.ant-switch{
        border-radius: 10px;

        &.ant-switch-checked.ant-switch-small{
            background: $color-citron;
        }
    }

    &__list{
        margin-bottom: 20px;

        &__content{
            overflow-y: scroll;
            height: 90%;
            &::-webkit-scrollbar{
                display: none;
                width: 0;
            }
        }
    }
}