@import "../../../../shared/styles/styles.scss";

.modal-details {
    &__content {
        height: 100%;
        width: 100%;

        &--mod{
            top: 50px;
            padding-bottom: 0px;
        }

        &>div:nth-child(2) {
            height: 100%;
        }

        &.ant-modal{
            &>div{
                height: 100%;
            }
        }

        .ant-modal-content {
            height: 100%;
            width: 100%;
            padding: 0;
            border-radius: 13px;
        }

        .ant-modal-body {
            height: 100%;
            width: 100%;
        }

        &__box {
            padding: 15px;
            background: $color-white;
            border-radius: 13px 13px 0 0;
        }
    }

    &__container.ant-modal-wrap {
        overflow: hidden;
    }

}