@import "../../../../../shared/styles/styles.scss";

.modal-electronic-invoice {

    &__title{
        @include global-typography($color-mine-shaft, bold, 14px);
        margin-top: 15px;
    }

    &__info{
        @include global-typography($color-dove-gray, normal, 12px);
        text-align: center;
        width: 90%;

        &__error{
            @include global-typography($color-lipstick, bold, 12px);
        }
    }

    &__text{
        @include global-typography($color-dove-gray, bold, 12px);
        margin-top: 5px;
    }

    &__input.ant-input{
        border-radius: 5px;
        border: 1px solid $color-nobel;
        margin-bottom: 0px;
    }
}