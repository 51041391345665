@import "../../../shared/styles/styles.scss";

.price {

    &__title{
        @include global-typography($color-mine-shaft, bold, 16px, 20px);
        margin-left: 10px;
    }

    &__container{
        &__header{
            margin: 15px 40px;
        }
        
        &--mod{
            min-height: 42%;
        }

        &__right{
            width: 39%;
        }
        &__left{
            width: 59%;
        }
    }

    &__box{
        background: $color-white;
        @include global-box-shadow(30px);
        margin-top: 15px;
        margin-bottom: 15px;
        border-radius: 13px;

        &--mod{
            padding: 10px;
            padding-bottom: 60px;
        }
    }

    &__subtitle{
        @include global-typography($color-mine-shaft, bold, 12px, 30px);
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 30px;
        text-align: center;

        &__content{
            padding: 10px 5px;
            height: 50px;
            justify-content: center;

            &__text{
                padding: 5px;
                justify-content: center;
            }
        }
    }
    &__table{
        background: $color-white;
        padding-top: 10px;
        padding-bottom: 10px;
        @include global-box-shadow(30px);
        border-radius: 13px;
        margin-top: 2rem;

        &__line{
            border-bottom: 1px solid $color-nobel;
            width: 100%;
        }

        &__title{
            @include global-typography($color-mine-shaft, bold, 12px, 13px);
        }

        &__text{
            @include global-typography($color-mine-shaft, normal, 10px, 11px);

            &__green{
                color: $color-citron;
                font-weight: bold;
            }

            &__red{
                color: $color-lipstick;
                font-weight: bold;
            }
        }
    }
    &__separator{
        margin: 12px 0px;

        &--mod{
            padding-left: 40px;
        }
    }
    &__button{
        @include global-button-primary(30px);
        padding-left: 4px;
        padding-right: 4px;

        &__text{
            @include global-typography($color-white, bold, 13px, 13px);
            padding-right: 5px;
        }

        &--mod{
            width: 40%;
            height: 28px;
            font-size: 10px;
            font-weight: bold;
        }

        &__actions {
            @include global-button-primary(25px);
            padding-left: 5px;
            font-size: 12px;
            
        } 

    }
    &__tooltip{
        width: auto;
        margin: 10px;

        &__header {
            justify-content: space-between;
            padding-bottom: 2px;
        }

        &__body{
            padding-top: 10px;
        }
        &__name{
            @include global-typography($color-mine-shaft, bold, 15px, 13px)
        }
        &__status{
            @include global-typography($color-mine-shaft, normal, 10px, 11px)
        }
        &__description{
            width: 100%;
            align-items: center;
            justify-content: space-evenly;
            margin-bottom: 10px;
            

            &__text{
                background-color:#BFBFBF;
                color: #707070;
                padding: 3px;
                border-radius: 3px;
                margin-right: 8px;
                font-weight: bold;
                font-size: 12px;

                &--mod{
                    color: #707070;
                    font-weight: bold;
                    font-size: 12px;
                    margin-right: 8px;
                }
            }

        }
        
    }
    &__input {

        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 36px;

            &--mod {
                width: 85%;
            }

            &>.ant-input-suffix>.ant-input-clear-icon {
                margin: 0;
                position: absolute;
                right: 0;
            }

            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused {
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused {
                        border: 2px solid $color-sushi;
                    }
                }

                &:focus {
                    box-shadow: none;
                }
            }

            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);

            }
        }
    }

    &__errors{
        height: 10px;
        width: 90%;
        padding-left: 10px;
        @include global-typography($color-lipstick, normal, 10px, 11px)
    }

    &__info {
        @include global-typography($color-mine-shaft, 700, 12px);
        margin-top: 20px;

        &--mod {
            @include global-typography($color-mine-shaft, 700, 12px);
            margin-top: 1px;
        }

        &--mod-right {
            @include global-typography($color-dove-gray,400,12px);
            margin-top: 1px;
            margin-left: 90px;
        }
    }

    &__modal {
        &__title {
            @include global-typography($color-mine-shaft, bold, 16px, 20px);
            margin-top: 5px;
        }
        &__text {
            margin-top: 15px;
            font-size: 12px;

            &--mod {
                margin-left: 5px;
                font-size: 12px;
            }
        }
        &__button{
            @include global-button-primary(30px, 90%);
            margin-top: 10px;
        }
        &__cancel {
            width: 90%;
            margin-top: 20px;
        }
    }

    &__content-check {
        width: 90%;
        height: 30px;
        display: flex;
        margin-top: -6px;
        align-items: center;
        justify-content: space-evenly;
        @include global-typography($color-dove-gray,normal,14px,15px);

        &__check{

            &>.ant-checkbox-wrapper,
            &>.ant-checkbox-wrapper:hover{
    
                &>.ant-checkbox{
                    &>.ant-checkbox-inner{
                        border: 1px solid $color-dove-gray;
                    }
    
                    &.ant-checkbox-checked{
    
                        &>span.ant-checkbox-inner{
                            background: $color-wild-willow;
                            border: 1px solid $color-wild-willow;
                        }
    
                        &::after{
                            border-color: transparent;
                        }
                    }
                }

                &>span{
                    margin-left: 8px;
                }
            }
        }
    }
}