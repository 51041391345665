@import "../../../shared/styles/styles.scss";

.creation-shipments {

    &__title{
        @include global-typography($color-mine-shaft, bold, 16px, 20px);
        margin-left: 10px
    }

    &__container{
        &__header{
            margin: 10px auto;
        }
        
        &--mod{
            min-height: 42%;
        }
    }

    &__box{
        background: $color-white;
        @include global-box-shadow(30px);
        margin: 12px auto 20px auto;
        border-radius: 13px;
        max-height: 500px;
        overflow: scroll;


        &--mod{
            padding: 15px;
        }
    }

    &__subtitle{
        @include global-typography($color-mine-shaft, bold, 12px, 30px);
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 30px;
        text-align: center;
    }

    &__button{
        @include global-button-primary(30px, 165px);
        padding-left: 4px;

        &__text{
            margin-right: 5px;
        }

        &--mod{
            width: 40%;
            height: 28px;
            font-size: 10px;
            font-weight: bold;
        }

        &__actions {
            @include global-button-primary(23px);
            margin-top: 5px;
            margin-bottom: 5px;
            padding-left: 5px;
            font-size: 12px;
            
        } 
    }

    &__state {
        margin-left: 10px;
    }

    &__input {
        width: 100%;
        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-nobel;
            border-radius: 5px;
            padding: 3px;
            height: 30px;
            width: 90%;
            
            &.ant-input-affix-wrapper {

                &.ant-input-affix-wrapper-focused{
                    border: 2px solid $color-sushi;
                }

                &:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;

                    &.ant-input-affix-wrapper-focused{
                        border: 2px solid $color-sushi;
                    }
                }
                &:focus{
                    box-shadow: none;
                }
            }
            &>input {
                height: 100%;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
                @include global-typography($color-dove-gray, normal, 14px, 15px);
            }
        }
    }

    &__modal {
        &__title {
            @include global-typography($color-mine-shaft, bold, 16px, 20px);
            margin-top: 15px;
        }

        &__titleInput{
            margin-top: 15px;
        }
        &__text {
            margin-top: 15px;
            font-size: 12px;
        }
        &__button{
            @include global-button-primary(30px, 90%);
            margin-top: 10px;
        }
        &__cancel {
            width: 90%;
            margin-top: 20px;
        }
    }

    &__switch.ant-switch{
        border-radius: 10px;
        margin-top: 15px;
        
        &.ant-switch-checked,
        &.ant-switch-checked:hover{
            background: $color-citron;
        }
    }

    &__table{

        &__line{
            border-bottom: 1px solid $color-nobel;
            margin-top: 6px;
        }

        &__text{
            @include global-typography($color-mine-shaft, normal, 12px, 11px);

            &__green{
                color: $color-citron;
                font-weight: bold;
            }

            &__red{
                color: $color-lipstick;
                font-weight: bold;
            }
        }
    }
}