@import '../../../shared/styles/styles.scss';

.recovery-password {

    &__container {
        align-items: center;
        justify-content: center;
        background-color: $color-white;
        border-radius: 13px;
        padding: 30px;
        width: 440px;
        height: 719px;
        @include global-box-shadow(32px)
    }

    &__form-content {
        width: 100%;
        justify-content: space-around;

        &__title {
            text-align: center;
            @include global-typography($color-mine-shaft, bold, 18px, 19px)
        }

        &__description{
            text-align: center;
            @include global-typography($color-mine-shaft, normal, 14px, 15px)
        }

        &__field {

            &__description {
                height: 10px;
                padding-left: 15px;

                &__text {
                    @include global-typography($color-dove-gray, normal, 10px, 11px);
                }
            }

            &__title {
                padding-left: 15px;
                @include global-typography($color-mine-shaft, bold, 14px, 15px);

                &__icon {
                    margin: 2px 0 0 3px;
                    width: 6px;
                    height: 6px;
                    border-radius: 6px;
                    background-color: $color-lipstick;
                }
            }

            &__input {
                display: flex;
                align-items: center;
                margin: 5px 0;
                border-radius: 5px;
                border-color: $color-nobel;
                height: 34px;
                padding: 4px 0px 4px 15px;
                outline: none;
                @include global-typography($color-mine-shaft, bold, 14px, 15px);

                &>.ant-input-suffix>.ant-input-clear-icon {
                    margin: 0;

                }


                &.ant-input-affix-wrapper:hover {
                    border: 2px solid $color-nobel;
                    box-shadow: none;
                }

                &--mod {
                    border: 2px solid $color-citron;
                }


                &>input {
                    box-shadow: none;
                    height: 24px;
                    margin-bottom: 0;

                }
            }

        }
    }

    &__link {
        @include global-typography($color-citron, normal, 16px, 17px);
        transition: all .2s;

        &:hover {
            color: $color-citron;
            font-weight: bold
        }
    }


    &__button {
        @include global-button-primary(34px, 100%);

        &[type=submit] {
            appearance: none;
            border-radius: 5px;
        }
    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .recovery-password {
        &__container {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
    }
}