@import "/src/shared/styles/styles.scss";

.sidebar-info{

    &__title{
        @include global-typography($color-mine-shaft,700,14px);
        text-align: center;
    }

    &__subtitle{
        @include global-typography($color-dove-gray,400,14px)
    }

    &__selection{

        &.MuiListItemIcon-root{
            margin-right: 5px;
        }


        &__sub-menu{

            &.MuiListItemText-root{

                &>span{
                    @extend .sidebar-info__subtitle;
                }
            }
        }
    }

    &__line{

        &.MuiDivider-root{
            height: 0px;
            margin-left: 15px;
        }
        
    }

    &__hide{
        visibility: hidden;
    }

    &__img-profile{
        margin-bottom: 15px;

        &--mod{
            width: 70%;
        }
    }

    &__icon{
        height: 30px;
        width: 30px;

        &__content-active{

            &.MuiListItem-root{
                background: $color-alabaster;
            }
        }

        &__content{
            &.MuiListItemIcon-root{
                min-width: 35px;
            }
        }
    }

    &__list{
        &.MuiList-root{
            padding: 0;
        }
    }

    &__text{

        &.MuiListItemText-root{

            &>span{
                @include global-typography($color-dove-gray,700,14px);
            }

        }

        &--active{
            @extend .sidebar-info__text;
            &.MuiListItemText-root{

                &>span{
                    color: $color-mine-shaft;
                }
    
            }
        }
    }
}
