@import "../../../../shared/styles/styles.scss";

.vehicle-overhaul{

    &__content{
        padding: 20px 10px;
        padding-bottom: 0;

        &__right{
            padding-right: 10px;
            border-right: 0.5px solid $color-nobel;
            border-top: 1px solid $color-nobel;
        }

        &__left{
            padding-left: 10px;
            border-left: 0.5px solid $color-nobel;
            border-top: 1px solid $color-nobel;
        }
    }

    &__subtitle{
        @include global-typography($color-mine-shaft,bold,12px,13px);
    }

    &__header{
        margin-bottom: 10px
    }

    &__text{
        @include global-typography($color-mine-shaft,normal,12px);

        &__title{
            @include global-typography($color-dove-gray,bold,14px);
            margin-left: 5px;
        }

        &__subtitle{
            @include global-typography($color-mine-shaft,bold,12px);
        }

        &__list{
            @include global-typography($color-mine-shaft,bold,14px);

            &__content{
                background-color: $color-wild-sand;
                height: 32px;
            }

            &__item{
                margin-top: 15px;
            }

            &__box{
                margin-bottom: 23px;
            }
        }

        &__button-pink{
            @include global-button-pink(34px,40%);
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }

        &__button-green{
            @include global-button-secondary(34px,40%);
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }
    }

    &__title{

        @include global-typography($color-mine-shaft,bold,16px);
        padding-left: 10px;
        
        &__content{
            padding-top: 15px;
            padding-left: 15px;
            padding-bottom: 8px;
            display: flex;
            align-items: center;
        }
    }

    &__info{
        background: $color-white;
        @include global-box-shadow(12px);
        border-radius: 13px;
        height: 150px;
        padding: 20px 15px;
    }

    &__driver{
        background: $color-white;
        @include global-box-shadow(12px);
        border-radius: 13px;
        height: 150px;
        padding: 20px 15px;
    }

    &__table{
        background: $color-white;
        @include global-box-shadow(12px);
        border-radius: 13px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-top: 20px;
        height: 58vh;
        padding:  15px 30px;

        &__content{
            overflow-y: scroll;
            height: 80%;

            &::-webkit-scrollbar{
                width: 0px;
            }
        }
    }

    &__button-back{
        @include global-button-secondary(35px, 110px);
        margin-top: 10px;
    }

    &__button-save{
        @include global-button-primary(35px, 110px);
        margin-top: 10px;
    }

    &__select-week{
        border: 1px solid $color-citron;
        height: 34px;
        border-radius: 5px;
        background: $color-white;

        &__date{
            width: 55%;
            border: 1px solid $color-nobel;
            height: 34px;
            box-shadow: none;
            cursor: pointer;
    
            &:hover{
                border: 2px solid $color-pine-glade;
            }
    
            &.ant-picker-focused{
                border: 2px solid $color-pine-glade;
            }
    
            &.ant-picker-disabled{
                background: $color-mercury;
                border: 2px solid $color-nobel;
    
                &>.ant-picker-input{
                    &>input{
                        @include global-typography($color-dove-gray,normal,14px,15px);
                    }
                }
            }
    
            &__picker{
                &>.ant-picker-panel-container{
                    &>.ant-picker-panel-layout{
                        &>.ant-picker-panel{
                            &>.ant-picker-week-panel{
                                &>.ant-picker-header{
                                    &>.ant-picker-header-view{
                                        &>button{
                                            text-transform: capitalize;
                                            @include global-typography($color-dove-gray,bold,14px);
                                        }
                                    }
                                }
                                &>.ant-picker-body{
                                    &>.ant-picker-content{
                                        &>thead{
                                            &>tr{
                                                &>th{
                                                    text-transform: uppercase;
                                                    @include global-typography($color-mine-shaft,bold,14px,15px)
                                                }
                                            }
                                        }
                                        &>tbody{
                                            &>tr{
                                                &.ant-picker-week-panel-row-selected{
                                                    &>td{
                                                        &::before{
                                                            background: $color-citron;
                                                        }
                                                    }
                                                }
                                                &>td{
                                                    &.ant-picker-cell-today{
    
                                                        &>div{
                                                            &::before{
                                                                border: 1px solid $color-citron;
                                                            }
                                                        }
                                                    }

                                                    &.ant-picker-cell-selected{
                                                        &>div{
                                                            background: transparent;
                                                            @include global-typography($color-citron,bold,14px);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &>.ant-picker-footer{
                                &>a{
                                    @include global-button-secondary;
                                    padding: 5px;
                                }
                            }
                            &>.ant-picker-month-panel{
                                &>.ant-picker-body{
                                    &>.ant-picker-content{
                                        &>tbody{
                                            &>tr{
                                                &>td{
                                                    &>div{
                                                        text-transform: capitalize;
                                                    }
                                                    &.ant-picker-cell-selected{
                                                        &>div{
                                                            background: $color-citron;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &>.ant-picker-year-panel{
                                &>.ant-picker-header{
                                    &>.ant-picker-header-view{
                                        &>button{
                                            &:hover{
                                                color: $color-citron;
                                            }
                                        }
                                    }
                                }
                                &>.ant-picker-body{
                                    &>.ant-picker-content{
                                        &>tbody{
                                            &>tr{
                                                &>td{
                                                    &.ant-picker-cell-selected{
                                                        &>div{
                                                            background: $color-citron;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &>.ant-picker-date-panel>.ant-picker-body>.ant-picker-content>tbody>tr>td{

                                &.ant-picker-cell-selected>.ant-picker-cell-inner{
                                    background: $color-citron;
                                }
                            }
                        }
    
                    }
                }
            }
    
        }
    }

    &__select {
        margin-right: 10px;

        &.ant-select {
            &>div.ant-select-selector {
                border: 1px solid $color-nobel;
                border-radius: 5px;
                height: 34px;
                box-shadow: none;
                cursor: pointer;
            }

            &.ant-select-focused {

                &.css-dev-only-do-not-override-ixblex {
                    &.ant-select-single {
                        &.ant-select-show-arrow {

                            &:hover {
                                &>div.ant-select-selector {
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &.ant-select-open {
                                &>.ant-select-selector {
                                    box-shadow: none;
                                    border: 2px solid $color-wild-willow;
                                }
                            }

                            &>.ant-select-selector {
                                box-shadow: none;
                                border: 2px solid $color-wild-willow;
                            }
                        }
                    }
                }
            }

            &.css-dev-only-do-not-override-ixblex {

                &.ant-select-single {
                    &.ant-select-show-arrow {
                        &:hover {
                            &>div.ant-select-selector {
                                border: 2px solid $color-nobel;
                            }
                        }
                    }
                }
            }
        }

        &.ant-select.ant-select-status-error.ant-select-show-arrow {
            &>.ant-select-selector {
                border: 2px solid $color-lipstick;
            }
        }
    }

    &__buttons{
        justify-content: space-between;
    }

    &__week{
        height: 80%;

        &__day{
            width: calc(80% / 7);
            border-left: 1px solid $color-alto;

            &:last-child{
                border-right: 1px solid $color-alto;
            }

            &--mod{
                width: 20%;
                border-left: 0px;
            }

            &__content{
                height: 30px;
                border-bottom: 1px solid $color-alto;
                padding: 3px 0px 5px 5px;
                align-items: center;
                display: flex;

                &--mod{
                    height: 60px;
                }

                &--modification{
                    height: 80px;
                }
            }

            &__box{
                width: 100%;
            }
        }

        &__result-info{
            height: 30px;
            border-bottom: 1px solid $color-alto;
            align-items: center;
            display: flex;
            justify-content: center;

            &--mod{
                height: 60px;
            }

            &--modification{
                height: 80px;
            }
        }

        &__label{
            writing-mode: tb-rl;
            transform: rotate(180deg);
            text-align: center;
            @include global-typography($color-mine-shaft,bold,12px);
            border: 1px solid $color-alto;
            border-bottom: 0px;
            width: 30px;

            &--mod{
                font-size: 10px;
                line-height: 12px;
            }
        }
        
        &__info{
            @include global-typography($color-mine-shaft,normal,10px);

            &--mod{
                font-weight: bold;
                text-transform: capitalize;
            }

            &__content{
                height: 30px;
            }
        }

        &__title{
            @include global-typography($color-mine-shaft,bold,12px);
        }

        &__result{
            @include global-typography($color-dove-gray,bold,8px);
        }

        &__content{
            height: 85%;
            overflow-y: scroll;

            &::-webkit-scrollbar{
                overflow: hidden;
                width: 0px;
            }
        }

        &__container{
            border: 1px solid $color-alto;
            border-right: 0px;
        }
    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .vehicle-overhaul{

        &__box-input{
            display: none;
        }

        &__content{
            
            &__right{
                padding: 0;
                border: 0;
            }

            &__left{
                padding: 0;
                border: 0;
            }
        }

        &__title{
            
            &__content{
                display: none;
            }
        }

        &__info{
            
            height: 180px;
            margin-top: 10px;
            margin-bottom: 10px;

            &__content{
                justify-content: space-between;
            }

            &__header{
                margin-bottom: 10px;
            }
        }

        &__select {
            display: none;
        }

        &__select-week{
            width: 100%;
        }

        &__text{
            
            &__list{
                
                &__box{
                    border: 1px solid $color-nobel;
                    border-radius: 5px;
                    padding-bottom: 10px;
                }

                &__content{
                    border-radius: 5px;
                }

                &__item{
                    padding: 0px 10px;
                }
            }
        }

        &__table{

            height: auto;
            padding-bottom: 100px;

            &__content{
                height: auto;
                overflow: hidden; 
            }
        }

        &__buttons{
           position: fixed;
           bottom: 0;
           background-color: $color-white;
           height: 80px;
           width: 100%;
           left: 0;
           justify-content: space-between;
           align-items: center;
           padding: 0px 20px;
           @include global-box-shadow(30px);
        }

        &__button-back,
        &__button-save{
            margin-top: 0px;
        }
    }
}

//Medium
@media screen and (min-width: $media-query-small-max) {
    .vehicle-overhaul{
        &__box-small{
            display: none;
        }
    }
}