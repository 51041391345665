@import '../../../shared/styles/styles.scss';

.validate-code-page {

    &__container {
        align-items: center;
        justify-content: center;
        background-color: $color-white;
        border-radius: 13px;
        padding: 30px;
        width: 440px;
        height: 719px;
        @include global-box-shadow(32px)
    }

    &__form-content {
        width: 100%;
        justify-content: space-around;

        &__title {
            text-align: center;
            @include global-typography($color-mine-shaft, bold, 18px, 19px);
        }

        &__description {
            text-align: center;
            padding: 0 20px;
            @include global-typography($color-mine-shaft, normal, 14px, 15px);
        }
        
        &__counter {
            @include global-typography($color-mine-shaft, bold, 18px, 19px);
        }
        
        &__error{
            text-align: center;
            padding: 0 25px;
            @include global-typography($color-lipstick, bold, 12px, 13px);
        }
    }

    &__button {
        @include global-button-primary(34px, 100%);

        &[type=submit] {
            appearance: none;
            border-radius: 5px;
        }
    }

    &__resend-email {
        margin: 6px 0  25px 0;
        &__text {
            margin:4px 3px;
            @include global-typography($color-mine-shaft, normal, 16px, 16px);
        }

        &__link {
            @include global-typography($color-citron, normal, 16px, 16px);
            transition: all .2s;

            &:hover {
                color: $color-sycamore;
            }
        }

    }
}

//media queries
//Small
@media screen and (max-width: $media-query-small-max) {
    .validate-code-page {
        &__container {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
    }
}